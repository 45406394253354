import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import api from "../../api/api";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  "& .loading": {
    display: "flex",
    alignItems: "center",

    "& .MuiCircularProgress-root": {
      marginRight: theme.spacing(1),
    },
  },
}));

const BackdoorDialog = (props) => {
  const [backdoor, setBackdoor] = useState();

  useEffect(() => {
    if (props.accountId) {
      api
        .postData(`/accounts/backdoor/${props.accountId}`, {}, {})
        .then((response) => {
          const data = response.data;

          setBackdoor(data);
        });
    }
  }, [props.accountId, setBackdoor]);

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Backdoor</DialogTitle>
      <StyledDialogContent>
        {backdoor ? (
          <>
            <Typography>
              The backdoor for account {backdoor.email} in application{" "}
              {backdoor.applicationId} is
            </Typography>
            <Typography>
              <pre>{backdoor.usefulInfo}</pre>
            </Typography>
            <Typography>
              Copy the backdoor now, as it will not be shown again. It expires
              in {backdoor.expiryMins} minutes and will be invalidated if you
              create a new one.
            </Typography>
          </>
        ) : (
          <div className="loading">
            <CircularProgress size={24} />
            <Typography>Creating backdoor...</Typography>
          </div>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose?.();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BackdoorDialog.propTypes = {
  open: PropTypes.bool,
  accountId: PropTypes.string,
  onClose: PropTypes.func,
};

BackdoorDialog.defaultProps = {
  open: false,
};

export default BackdoorDialog;
