export const reshapeApplication = (application) => {
  const date = new Date();

  return {
    ...application,
    createDate: date,
    changeDate: date,
    enabled: application.userActivityEnabled,
    humanName: application.applicationName,
    subApplication: application.subApplicationEnabled,
    superApplicationId: application.subApplicationAccountSource,
    accessMode: application.visibilityMode,
    url: application.accessUrl,
    webInfo: {
      logoUrl: application.logoUrl,
      foreColor: application.colors.foreground,
      backColor: application.colors.background,
      linkColor: application.colors.link,
      requirement: application.requirementText,
    },
    paramDefaults: {
      textParam: application.defaultParameters,
    },
  };
};

export const reshapeApplications = (data) => data.applications;
