import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DenseTableCell from "../Common/DenseTableCell";

const ImportedAccountList = (props) => (
  <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          <DenseTableCell>e-mail</DenseTableCell>
          <DenseTableCell>Last name</DenseTableCell>
          <DenseTableCell>First name</DenseTableCell>
          <DenseTableCell>Parameters</DenseTableCell>
          <DenseTableCell>Source</DenseTableCell>
          <DenseTableCell>Password</DenseTableCell>
          <DenseTableCell>Status</DenseTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.accounts.map((account, index) => (
          <TableRow key={index}>
            <DenseTableCell>{account.email}</DenseTableCell>
            <DenseTableCell>{account.lastName}</DenseTableCell>
            <DenseTableCell>{account.firstName}</DenseTableCell>
            <DenseTableCell>{account.parameters}</DenseTableCell>
            <DenseTableCell>{account.source}</DenseTableCell>
            <DenseTableCell>{account.password}</DenseTableCell>
            <DenseTableCell>{account.status}</DenseTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

ImportedAccountList.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      parameters: PropTypes.string,
      source: PropTypes.string,
      status: PropTypes.string,
    })
  ),
};

ImportedAccountList.defaultProps = {
  accounts: [],
};

export default ImportedAccountList;
