import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@material-ui/core";
import PropTypes from "prop-types";

const StyledComponent = styled("div")(({ theme }) => ({
  "& .MuiList-root": {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  "& .MuiListItemIcon-root": {
    marginRight: theme.spacing(1),
    minWidth: "auto",

    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
}));

const SelectList = (props) => {
  const handleChange = (id, value) => {
    props.onChange?.(
      value
        ? [...props.chosenIds, id]
        : props.chosenIds.filter((chosenId) => chosenId !== id)
    );
  };

  return (
    <StyledComponent>
      <List>
        {props.choices.map((choice) => (
          <ListItem key={choice.id} dense>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={props.chosenIds.includes(choice.id)}
                color="primary"
                disableRipple
                onChange={(e) => {
                  handleChange(choice.id, e.target.checked);
                }}
              />
            </ListItemIcon>
            <ListItemText primary={choice.label} />
          </ListItem>
        ))}
      </List>
    </StyledComponent>
  );
};

SelectList.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  chosenIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

SelectList.defaultProps = {
  choices: [],
  chosenIds: [],
};

export default SelectList;
