import { createRef } from "react";
import { Button } from "@material-ui/core";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { HashRouter as Router } from "react-router-dom";
import { ProvideApplicationDetails } from "../hooks/useApplicationDetails";
import { ProvideAuthentication } from "../hooks/useAuthentication";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const theme = createMuiTheme();

const notistackRef = createRef();

const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const Configuration = ({ children }) => (
  <Router>
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={2000}
        ref={notistackRef}
        action={(key) => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}
      >
        <QueryClientProvider client={queryClient}>
          <ProvideApplicationDetails>
            <ProvideAuthentication>{children}</ProvideAuthentication>
          </ProvideApplicationDetails>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  </Router>
);

export default Configuration;
