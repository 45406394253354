import { styled } from "@material-ui/styles";
import { useState } from "react";
import { downloadAccountsCsv, downloadStatisticReport } from "../../api/api";
import { reshapeAccount } from "../../data/reshapeAccounts";
import { useFetchAccounts, useFetchApplications } from "../../hooks/useQueries";
import useResourceEditing from "../../hooks/useResourceEditing";
import Error from "../Common/Error";
import AccountDetailsDialog from "./AccountDetailsDialog";
import AccountsToolbar from "./AccountsToolbar";
import AssignRolesDialog from "./AssignRolesDialog";
import BackdoorDialog from "./BackdoorDialog";
import PaginatedAccountList from "./PaginatedAccountList";
import StatisticExportDialog from "./StatisticExportDialog";
import UnlimitedCountIndicator from "./UnlimitedCountIndicator";

const StyledPaginatedAccountList = styled(PaginatedAccountList)(
  ({ theme }) => ({
    marginTop: `${theme.spacing(2)}px`,
  })
);

const PopulatedAccountDetailsDialog = (props) => {
  const account = props.account;

  return (
    <AccountDetailsDialog
      open={props.open}
      applications={props.applications}
      applicationId={account.applicationId}
      email={account.email}
      disabled={account.isDisabled}
      verified={account.isValidated}
      firstName={account.firstName}
      lastName={account.lastName}
      organization={account.organization}
      parameters={account.applicationLinks?.[0]?.userParameters?.textParam}
      source={account.source}
      createdInApplicationId={account.createdInApp}
      systemKeys={account.systemKeyValues?.reduce(
        (accumulation, { keyName, keyValue }) =>
          Object.assign(accumulation, { [keyName]: keyValue }),
        {}
      )}
      accountId={account.accountId}
      locked={!!account.email}
      saving={props.saving}
      deleting={props.deleting}
      error={props.error}
      onSave={props.onSave}
      onCancel={props.onCancel}
      onDelete={props.onDelete}
    />
  );
};

const Accounts = () => {
  const [recordLimit, setRecordLimit] = useState(10);
  const [applicationId, setApplicationId] = useState();
  const [filterTerm, setFilterTerm] = useState();
  const [statisticExportDialogOpen, setStatisticExportDialogOpen] = useState(
    false
  );
  const [backdoorAccountId, setBackdoorAccountId] = useState();
  const [activeAccountId, setActiveAccountId] = useState();

  const { applications } = useFetchApplications();

  const {
    isLoading,
    isError,
    accounts,
    unlimitedCount,
    error,
  } = useFetchAccounts(undefined, applicationId, recordLimit, filterTerm);

  const {
    resourceState,
    editResource,
    addResource,
    updateResource,
    deleteResource,
    cancelResourceEditing,
  } = useResourceEditing("accounts");

  if (isError) {
    const message = `Oops! Failed to fetch accounts. ${error.message}. Try refreshing the browser.`;

    return <Error message={message} />;
  }

  const handleOpenBackdoor = (accountId) => {
    setBackdoorAccountId(accountId);
  };

  const handleEditAccount = (accountId) => {
    const account = accounts.find((account) => account.accountId === accountId);

    editResource(account);
  };

  const handleSaveAccount = (account) => {
    const resource = reshapeAccount(account);

    if (!resourceState.data.accountId) {
      addResource.mutate(resource);
    } else {
      updateResource.mutate({
        ...resource,
        id: resource.accountId,
        AdminVerify: resource.isValidated,
      });
    }
  };

  const handleDeleteAccount = (accountId) => {
    deleteResource.mutate(accountId);
  };

  const handleStatisticDownload = (statistic) => {
    setStatisticExportDialogOpen(false);

    downloadStatisticReport(`${statistic}-report.csv`, statistic);
  };

  const handleAssignRoles = (account, params) => {
    const resource = {
      ...account,
      id: account.accountId,
      roles: params.assignedRoleIds,
      keyAccessToken: params.keyAccessToken,
    };

    updateResource.mutate(resource);

    setActiveAccountId();
  };

  const activeAccount = accounts?.find(
    (account) => account.accountId === activeAccountId
  );

  return (
    <div>
      <AccountsToolbar
        recordLimit={recordLimit}
        applications={applications?.filter(({ enabled }) => enabled)}
        applicationId={applicationId}
        onAddAccount={() => {
          editResource({});
        }}
        onFetch={({ recordLimit, applicationId, filterTerm }) => {
          setRecordLimit(recordLimit ?? 0);
          setApplicationId(applicationId);
          setFilterTerm(filterTerm);
        }}
        onExportStatistics={() => {
          setStatisticExportDialogOpen(true);
        }}
        onDownloadCsv={() => {
          downloadAccountsCsv("accounts.csv", undefined, applicationId);
        }}
      />

      <UnlimitedCountIndicator
        applicationId={applicationId}
        count={unlimitedCount}
      />

      <StyledPaginatedAccountList
        accounts={accounts}
        filterTerm={filterTerm}
        loading={isLoading}
        onOpenBackdoor={handleOpenBackdoor}
        onEditAccount={handleEditAccount}
        onAssignRoles={setActiveAccountId}
      />

      {resourceState.data && (
        <PopulatedAccountDetailsDialog
          open={true}
          applications={applications}
          account={resourceState.data}
          saving={resourceState.saving}
          deleting={resourceState.deleting}
          error={resourceState.error}
          onSave={handleSaveAccount}
          onCancel={cancelResourceEditing}
          onDelete={handleDeleteAccount}
        />
      )}

      {statisticExportDialogOpen && (
        <StatisticExportDialog
          open={true}
          onClose={() => {
            setStatisticExportDialogOpen(false);
          }}
          onDownload={handleStatisticDownload}
        />
      )}

      {backdoorAccountId && (
        <BackdoorDialog
          open={Boolean(backdoorAccountId)}
          accountId={backdoorAccountId}
          onClose={() => {
            setBackdoorAccountId();
          }}
        />
      )}

      {activeAccount && (
        <AssignRolesDialog
          open={Boolean(activeAccountId)}
          account={activeAccount}
          assignedRoleIds={activeAccount.roles}
          onSave={(params) => {
            handleAssignRoles(activeAccount, params);
          }}
          onCancel={() => {
            setActiveAccountId();
          }}
        />
      )}
    </div>
  );
};

export default Accounts;
