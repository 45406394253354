import PropTypes from "prop-types";
import { styled } from "@material-ui/styles";
import { NavLink } from "react-router-dom";

const StyledNavLink = styled(NavLink)({
  color: "white",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "0.9375rem",
  fontWeight: 500,
  textDecoration: "none",
  "&.active": {
    textDecoration: "underline",
  },
});

const MainMenuLink = (props) => (
  <StyledNavLink to={props.to}>{props.children}</StyledNavLink>
);

MainMenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default MainMenuLink;
