import { Button, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import {
  Assignment as AssignmentIcon,
  Refresh as RefreshIcon,
} from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import DialogForm from "../Common/DialogForm";
import LoadingButton from "../Common/LoadingButton";
import LockingDialogActions from "../Common/LockingDialogActions";
import SelectList from "../Common/SelectList";
import { useFetchRoles } from "../../hooks/useQueries";

const StyledDialogForm = styled(DialogForm)(() => ({
  "& .MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
  },
  "& .key-access-token": {
    alignItems: "center",
    display: "flex",
    marginTop: "1rem",

    "& > div:first-child": {
      flexGrow: 1,
    },
  },
}));

const AssignRolesDialog = (props) => {
  const [assignedRoleIds, setAssignedRoleIds] = useState(props.assignedRoleIds);
  const [keyAccessToken, setKeyAccessToken] = useState(
    props.account?.keyAccessToken ?? uuidv4()
  );
  const [locked, setLocked] = useState(true);

  const { roles } = useFetchRoles();

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Assign roles to {props.account?.email}</DialogTitle>
      <StyledDialogForm>
        <SelectList
          choices={roles
            ?.sort((a, b) => a.roleId.localeCompare(b.roleId))
            .map(({ roleId }) => ({
              id: roleId,
              label: roleId,
            }))}
          chosenIds={assignedRoleIds}
          onChange={(value) => {
            setAssignedRoleIds(value);
          }}
        />

        <div className="key-access-token">
          <div>Key access token: {keyAccessToken}</div>

          <IconButton
            size="small"
            disabled={locked}
            onClick={() => {
              setKeyAccessToken(uuidv4());
            }}
          >
            <RefreshIcon />
          </IconButton>

          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(keyAccessToken);
            }}
          >
            <AssignmentIcon />
          </IconButton>
        </div>
      </StyledDialogForm>
      <LockingDialogActions locked={locked} onLockChange={setLocked}>
        <Button color="primary" onClick={props.onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={false}
          onClick={() => {
            props.onSave?.({
              assignedRoleIds,
              keyAccessToken,
            });
          }}
        >
          Save
        </LoadingButton>
      </LockingDialogActions>
    </Dialog>
  );
};

AssignRolesDialog.propTypes = {
  open: PropTypes.bool,
  account: PropTypes.shape({
    email: PropTypes.string.isRequired,
    keyAccessToken: PropTypes.string,
  }),
  assignedRoleIds: PropTypes.arrayOf(PropTypes.string),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

AssignRolesDialog.defaultProps = {
  open: false,
  assignedRoleIds: [],
};

export default AssignRolesDialog;
