import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";

const IFrame = ({ children, title, autoSizeHeight, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  useEffect(() => {
    const adjustHeight = () => {
      const iframeDomNode = ReactDOM.findDOMNode(contentRef);

      if (iframeDomNode) {
        iframeDomNode.height = mountNode?.scrollHeight || "auto";
      }
    };

    if (autoSizeHeight) {
      adjustHeight();
    }
  }, [autoSizeHeight, contentRef, mountNode]);

  return (
    <iframe
      {...props}
      title={title}
      ref={setContentRef}
      style={{
        border: "none",
      }}
    >
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

IFrame.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default IFrame;
