import PropTypes from "prop-types";
import { Lock as LockIcon, LockOpen as LockOpenIcon } from "@material-ui/icons";
import BinarySwitch from "./BinarySwitch";

const LockSwitch = (props) => (
  <BinarySwitch
    falseLabel={
      <LockOpenIcon
        onClick={() => {
          props.onChange?.(false);
        }}
      />
    }
    trueLabel={
      <LockIcon
        onClick={() => {
          props.onChange?.(true);
        }}
      />
    }
    checked={props.locked}
    onChange={props.onChange}
  />
);

LockSwitch.propTypes = {
  locked: PropTypes.bool,
  onChange: PropTypes.func,
};

LockSwitch.defaultProps = {
  locked: true,
};

export default LockSwitch;
