import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import DialogForm from "../Common/DialogForm";
import { useFetchKeyHolders } from "../../hooks/useQueries";

const StyledDialogForm = styled(DialogForm)(() => ({
  "& em": {
    fontStyle: "normal",
    fontWeight: "bold",
  },
  "& .MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
  },
}));

const KeyHoldersDialog = (props) => {
  const { keyHolders } = useFetchKeyHolders(props.keyId);

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Key holders</DialogTitle>
      <StyledDialogForm>
        <DialogContentText>
          {keyHolders?.length ? (
            <span>
              The following users have access to <em>{props.keyId}</em>.
            </span>
          ) : (
            <span>
              No users have access to <em>{props.keyId}</em>.
            </span>
          )}
        </DialogContentText>

        <List>
          {keyHolders
            ?.sort((a, b) =>
              a.referenceComment.localeCompare(b.referenceComment)
            )
            .map((keyHolder) => (
              <ListItem key={keyHolder.userId}>
                <ListItemText>
                  {keyHolder.referenceComment ?? keyHolder.userId}
                </ListItemText>
              </ListItem>
            ))}
        </List>
      </StyledDialogForm>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            props.onClose?.();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

KeyHoldersDialog.propTypes = {
  open: PropTypes.bool,
  keyId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

KeyHoldersDialog.defaultProps = {
  open: false,
};

export default KeyHoldersDialog;
