import { Button, Dialog, DialogTitle, TextField } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import LockingDialogActions from "../Common/LockingDialogActions";
import DialogForm from "../Common/DialogForm";
import LoadingButton from "../Common/LoadingButton";
import DeleteRoleDialog from "./DeleteRoleDialog";

const StyledDialogForm = styled(DialogForm)(() => ({
  "& .MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
  },
}));

const RoleDetailsDialog = ({ roles, ...rest }) => {
  const [roleId, setRoleId] = useState(rest.roleId ?? "");
  const [locked, setLocked] = useState(true);
  const [confirmDeletionDialogOpen, setConfirmDeletionDialogOpen] = useState(
    false
  );

  const roleIds = useMemo(() => roles.map((role) => role.roleId), [roles]);

  const checkValidity = () =>
    Boolean(roleId.trim() && !roleIds.includes(roleId));

  const handleDelete = () => {
    setConfirmDeletionDialogOpen(false);

    rest.onDelete?.(rest.roleId);
  };

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={rest.open}>
        <DialogTitle>Role details</DialogTitle>
        <StyledDialogForm>
          <TextField
            label="ID"
            value={roleId}
            required
            fullWidth
            helperText={
              roleIds.includes(roleId) ? "Role ID already exists" : ""
            }
            disabled={Boolean(rest.roleId)}
            onChange={(e) => {
              setRoleId(e.target.value);
            }}
          />
        </StyledDialogForm>
        <LockingDialogActions locked={locked} onLockChange={setLocked}>
          <Button color="primary" onClick={rest.onCancel}>
            Cancel
          </Button>
          <LoadingButton
            color="secondary"
            disabled={locked || !rest.roleId}
            loading={false}
            onClick={() => {
              setConfirmDeletionDialogOpen(true);
            }}
          >
            Delete
          </LoadingButton>
          <LoadingButton
            color="primary"
            disabled={Boolean(rest.roleId) || !checkValidity()}
            loading={false}
            onClick={() => {
              rest.onSave?.({
                roleId: roleId.trim(),
                keys: [],
              });
            }}
          >
            Save
          </LoadingButton>
        </LockingDialogActions>
      </Dialog>

      {confirmDeletionDialogOpen && (
        <DeleteRoleDialog
          open={true}
          onCancel={() => {
            setConfirmDeletionDialogOpen(false);
          }}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

RoleDetailsDialog.propTypes = {
  open: PropTypes.bool,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      roleId: PropTypes.string.isRequired,
    })
  ),
  roleId: PropTypes.string,
};

RoleDetailsDialog.defaultProps = {
  open: false,
  roles: [],
};

export default RoleDetailsDialog;
