import { useSnackbar } from "notistack";
import { sendMailTemplateTestMessage } from "../../api/api";
import Error from "../Common/Error";
import { reshapeMailTemplate } from "../../data/reshapeMailTemplates";
import { useFetchMailTemplates } from "../../hooks/useQueries";
import useResourceEditing from "../../hooks/useResourceEditing";
import MailTemplateList from "./MailTemplateList";
import MailTemplatesToolbar from "./MailTemplatesToolbar";
import TemplateDetailsDialog from "./TemplateDetailsDialog";

const PopulatedTemplateDetailsDialog = (props) => {
  const template = props.template;

  return (
    <TemplateDetailsDialog
      open={props.open}
      templateId={template.templateId}
      enabled={template.enabled}
      internalPurpose={template.internalPurpose}
      subject={template.subject}
      templateText={template.templateText}
      locked={!!template.templateId}
      onSave={props.onSave}
      onCancel={props.onCancel}
    />
  );
};

const MailTemplates = () => {
  const { isLoading, isError, mailTemplates, error } = useFetchMailTemplates();

  const {
    resourceState,
    editResource,
    addResource,
    updateResource,
    cancelResourceEditing,
  } = useResourceEditing("mail-templates");

  const { enqueueSnackbar } = useSnackbar();

  if (isError) {
    const message = `Oops! Failed to fetch mail templates. ${error.message}. Try refreshing the browser.`;

    return <Error message={message} />;
  }

  const handleEditTemplate = (templateId) => {
    const template = mailTemplates.find(
      (template) => template.templateId === templateId
    );

    editResource(template);
  };

  const handleSaveTemplate = (template) => {
    const resource = reshapeMailTemplate(template);

    if (!resourceState.data.applicationId) {
      addResource.mutate(resource);
    } else {
      updateResource.mutate({
        ...resource,
        id: resource.templateId,
      });
    }
  };

  const handleSendTestMessage = async (mailTemplateId) => {
    try {
      await sendMailTemplateTestMessage(mailTemplateId);

      enqueueSnackbar("Test message sent.", {
        variant: "info",
      });
    } catch (error) {
      enqueueSnackbar("Failed to send test message", {
        variant: "error",
      });

      console.error(error);
    }
  };

  return (
    <div>
      <MailTemplatesToolbar
        onAddTemplate={() => {
          editResource({});
        }}
      />
      <MailTemplateList
        templates={mailTemplates}
        loading={isLoading}
        onEditTemplate={handleEditTemplate}
        onSendTestMessage={handleSendTestMessage}
      />
      {resourceState.data && (
        <PopulatedTemplateDetailsDialog
          open={true}
          template={resourceState.data}
          saving={resourceState.saving}
          error={resourceState.error}
          onSave={handleSaveTemplate}
          onCancel={cancelResourceEditing}
        />
      )}
    </div>
  );
};

export default MailTemplates;
