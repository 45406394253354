import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";

const CONFIRMATION_CODE = "forever";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  "& .MuiTypography-root": {
    marginTop: `${theme.spacing(1)}px`,
  },
}));

const DeleteApplicationDialog = (props) => {
  const [confirmationCode, setConfirmationCode] = useState("");

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Account deletion</DialogTitle>
      <StyledDialogContent>
        <Typography variant="body1">
          Deleting an application will irrevocably remove all information about
          the application.
        </Typography>
        <Typography variant="body1">
          Consider disabling user activity for the application instead.
        </Typography>
        <Typography variant="body1">
          If you still wish to permanently delete the application, enter
          {` '${CONFIRMATION_CODE}'`} to confirm you understand the
          implications.
        </Typography>

        <TextField
          label="Confirmation"
          required
          fullWidth
          helperText={`Enter '${CONFIRMATION_CODE}' to confirm deletion`}
          value={confirmationCode}
          onChange={(e) => {
            setConfirmationCode(e.target.value);
          }}
        />
      </StyledDialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={props.onDelete}
          disabled={confirmationCode !== CONFIRMATION_CODE}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteApplicationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DeleteApplicationDialog;
