import { useQueryClient } from "react-query";
import { deleteData, postData, putData } from "../api/api";
import { createAction } from "../utils";
import useEditReducer from "./useEditReducer";
import useMutation from "./useMutation";

const useAddResource = (resourceType, dispatch, queryClient) =>
  useMutation((resource) => postData(`/${resourceType}`, resource), {
    onMutate: () => {
      dispatch(createAction("saving"));
    },
    onSuccess: () => {
      dispatch(createAction("saved"));

      queryClient.invalidateQueries(resourceType);
    },
    onError: (error) => {
      dispatch(createAction("failed", error.response.data.message));
    },
  });

const useUpdateResource = (resourceType, dispatch, queryClient) =>
  useMutation(
    (resource) =>
      putData(
        `/${resourceType}/${resource.previousId ?? resource.id}`,
        resource
      ),
    {
      onMutate: () => {
        dispatch(createAction("saving"));
      },
      onSuccess: () => {
        dispatch(createAction("saved"));

        queryClient.invalidateQueries(resourceType);
      },
      onError: (error) => {
        dispatch(createAction("failed", error.response.data.message));
      },
    }
  );

const useDeleteResource = (resourceType, dispatch, queryClient) =>
  useMutation((resourceId) => deleteData(`/${resourceType}/${resourceId}`), {
    onMutate: () => {
      dispatch(createAction("deleting"));
    },
    onSuccess: () => {
      dispatch(createAction("deleted"));

      queryClient.invalidateQueries(resourceType);
    },
    onError: (error) => {
      let message = error.response.data.message;

      if (!message && error.response.data?.endsWith(" users exist")) {
        const users = parseInt(error.response.data.replace(" users exist", ""));

        message = `Cannot delete an application with ${users} user${
          users !== 1 ? "s" : ""
        }`;
      }

      dispatch(createAction("failed", message));
    },
  });

const useResourceEditing = (resourceType) => {
  const [state, dispatch] = useEditReducer();

  const queryClient = useQueryClient();

  const addResource = useAddResource(resourceType, dispatch, queryClient);
  const updateResource = useUpdateResource(resourceType, dispatch, queryClient);
  const deleteResource = useDeleteResource(resourceType, dispatch, queryClient);

  const edit = (resource) => {
    dispatch(createAction("editing", resource));
  };

  const cancel = () => {
    dispatch(createAction("canceled"));
  };

  return {
    resourceState: state,
    editResource: edit,
    addResource,
    updateResource,
    deleteResource,
    cancelResourceEditing: cancel,
  };
};

export default useResourceEditing;
