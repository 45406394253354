import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import { DEFAULT_APPLICATION_TITLE } from "../../constants";
import { useApplicationDetails } from "../../hooks/useApplicationDetails";
import { useAuthentication } from "../../hooks/useAuthentication";
import PasswordAuthentication from "./PasswordAuthentication";
import TokenAuthentication from "./TokenAuthentication";

const EMAIL_LOCAL_STORAGE_KEY = "email";

export const LoginDialogPresenter = (props) => {
  const handleAuthenticationTokenChange = (authenticationToken) => {
    if (/^\d{0,8}$/.test(authenticationToken)) {
      props.onAuthenticationTokenChange(authenticationToken);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        <PasswordAuthentication
          email={props.email}
          password={props.password}
          onEmailChange={props.onEmailChange}
          onPasswordChange={props.onPasswordChange}
        />

        <TokenAuthentication
          authenticationToken={props.authenticationToken}
          authenticationTokenSecretUrl={props.authenticationTokenSecretUrl}
          authenticationTokenSecretKey={props.authenticationTokenSecretKey}
          disabled={!props.authenticationTokenRequired}
          onAuthenticationTokenChange={handleAuthenticationTokenChange}
        />

        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={props.rememberMe}
                onClick={() => {
                  props.onRememberMeToggle?.();
                }}
              />
            }
            label="Remember me"
          />
          <FormHelperText>
            Do not enable this option on a public/shared computer
          </FormHelperText>
        </FormControl>
      </DialogForm>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            props.onSignIn?.();
          }}
        >
          Sign in
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LoginDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  authenticationTokenRequired: PropTypes.bool,
  authenticationTokenSecretUrl: PropTypes.string,
  authenticationTokenSecretKey: PropTypes.string,
  authenticationToken: PropTypes.string,
  rememberMe: PropTypes.bool,
  error: PropTypes.string,
  onEmailChange: PropTypes.func,
  onPasswordChange: PropTypes.func,
  onAuthenticationTokenChange: PropTypes.func,
  onRememberMeToggle: PropTypes.func,
  onSignIn: PropTypes.func,
};

LoginDialogPresenter.defaultProps = {
  title: DEFAULT_APPLICATION_TITLE,
  authenticationTokenRequired: true,
  rememberMe: false,
};

const getRememberedEmail = () =>
  localStorage.getItem(EMAIL_LOCAL_STORAGE_KEY)
    ? JSON.parse(localStorage.getItem(EMAIL_LOCAL_STORAGE_KEY))
    : undefined;

const LoginDialog = (props) => {
  const rememberedEmail = getRememberedEmail();

  const { title, authenticationTokenRequired } = useApplicationDetails();
  const [email, setEmail] = useState(rememberedEmail);
  const [password, setPassword] = useState();
  const [authenticationToken, setAuthenticationToken] = useState();
  const [rememberMe, setRememberMe] = useState(!!rememberedEmail);

  const authentication = useAuthentication();

  const handleSignIn = () => {
    authentication.signIn(email, password, rememberMe, authenticationToken);
  };

  let error = authentication.error;

  if (error === "Email or password is incorrect") {
    error = "Your credentials were invalid. Please check them and try again.";
  }

  if (error === "Two factor authentication must be supplied") {
    error = "2FA is enabled. You must supply a token to sign in.";
  }

  useEffect(() => {
    if (email && rememberMe) {
      localStorage.setItem(EMAIL_LOCAL_STORAGE_KEY, JSON.stringify(email));
    } else {
      localStorage.removeItem(EMAIL_LOCAL_STORAGE_KEY);
    }
  }, [email, rememberMe]);

  return (
    <LoginDialogPresenter
      {...props}
      title={title}
      email={email}
      password={password}
      authenticationTokenRequired={authenticationTokenRequired}
      authenticationTokenSecretUrl={authentication.authenticationTokenSecretUrl}
      authenticationTokenSecretKey={authentication.authenticationTokenSecretKey}
      authenticationToken={authenticationToken}
      rememberMe={rememberMe}
      error={error}
      onEmailChange={setEmail}
      onPasswordChange={setPassword}
      onAuthenticationTokenChange={setAuthenticationToken}
      onRememberMeToggle={() => {
        setRememberMe((rememberMe) => !rememberMe);
      }}
      onSignIn={handleSignIn}
    />
  );
};

export default LoginDialog;
