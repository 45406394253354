import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const StyledActions = styled("div")(() => ({
  maxHeight: 100,
  overflow: "auto",
}));

const MoveActions = ({ actions }) => (
  <div>
    <Typography variant="h6">Actions</Typography>

    <StyledActions>
      {actions.map((action, index) => (
        <Typography key={index} variant="body1">
          {action}
        </Typography>
      ))}
    </StyledActions>
  </div>
);

MoveActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string),
};

MoveActions.defaultProps = {
  actions: [],
};

export default MoveActions;
