import { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const PrivateTextInput = (props) => {
  const [showText, setShowText] = useState(false);

  return (
    <TextField
      type={showText ? "text" : "password"}
      fullWidth={props.fullWidth}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setShowText((showText) => !showText);
              }}
            >
              {showText ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      autoComplete="on"
      {...props}
      onChange={props.onChange}
    />
  );
};

PrivateTextInput.propText = {
  value: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PrivateTextInput;
