import { createContext, useContext } from "react";
import { DEFAULT_APPLICATION_TITLE } from "../constants";
import { useFetchMessages } from "./useQueries";

const applicationDetailsContext = createContext();

const useProvideApplicationDetails = () => {
  const { messages } = useFetchMessages();

  return {
    title: messages?.humanTitle ?? DEFAULT_APPLICATION_TITLE,
    splashMessage: messages?.splashMessage,
    footerMessage: messages?.footerMessage,
    authenticationTokenRequired:
      messages?.twoFactorStatus.toLowerCase() === "required",
  };
};

export const ProvideApplicationDetails = ({ children }) => {
  const applicationDetails = useProvideApplicationDetails();

  return (
    <applicationDetailsContext.Provider value={applicationDetails}>
      {children}
    </applicationDetailsContext.Provider>
  );
};

export const useApplicationDetails = () => {
  return useContext(applicationDetailsContext);
};
