import PropTypes from "prop-types";
import { Fab, Toolbar, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const SystemMailToolbar = (props) => (
  <Toolbar>
    <Tooltip title="Create new destination">
      <Fab color="primary" size="small" onClick={props.onAddDesitination}>
        <AddIcon />
      </Fab>
    </Tooltip>
  </Toolbar>
);

SystemMailToolbar.propTypes = {
  onAddDesitination: PropTypes.func,
};

export default SystemMailToolbar;
