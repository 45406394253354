import { useReducer } from "react";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "canceled": {
      return {
        ...state,
        data: undefined,
      };
    }

    case "deleted": {
      return {
        ...state,
        data: undefined,
        deleting: false,
      };
    }

    case "deleting": {
      return {
        ...state,
        deleting: true,
        error: undefined,
      };
    }

    case "editing": {
      return {
        ...state,
        data: payload,
        saving: false,
        error: undefined,
      };
    }

    case "failed": {
      return {
        ...state,
        saving: false,
        error: payload,
      };
    }

    case "saved": {
      return {
        ...state,
        data: undefined,
        saving: false,
      };
    }

    case "saving": {
      return {
        ...state,
        saving: true,
        error: undefined,
      };
    }

    default: {
      return state;
    }
  }
};

const useEditReducer = () =>
  useReducer(reducer, {
    saving: false,
    deleting: false,
  });

export default useEditReducer;
