import { useState } from "react";
import PropTypes from "prop-types";
import {
  Fab,
  FormControlLabel,
  Switch,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Add as AddIcon, Forward as ForwardIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "column",
  gridColumnGap: `${theme.spacing(3)}px`,
  width: "fit-content",
}));

const ApplicationsToolbar = (props) => {
  const [advancedFeatures, setAdvancedFeatures] = useState(false);

  return (
    <StyledToolbar>
      <Tooltip title="Create new application">
        <Fab color="primary" size="small" onClick={props.onAddApplication}>
          <AddIcon />
        </Fab>
      </Tooltip>

      <FormControlLabel
        control={<Switch color="primary" />}
        label="Show inactive"
        checked={props.showInactive}
        onChange={(e) => {
          props.onShowInactiveChange?.(e.target.checked);
        }}
      />

      <FormControlLabel
        control={<Switch color="primary" />}
        label="Advanced"
        checked={advancedFeatures}
        onChange={(e) => {
          setAdvancedFeatures(e.target.checked);
        }}
      />

      {advancedFeatures ? (
        <Tooltip title="Move accounts">
          <Fab color="secondary" size="small" onClick={props.onMoveAccounts}>
            <ForwardIcon />
          </Fab>
        </Tooltip>
      ) : null}
    </StyledToolbar>
  );
};

ApplicationsToolbar.propTypes = {
  showInactive: PropTypes.bool,
  onAddApplication: PropTypes.func,
  onShowInactiveChange: PropTypes.func,
  onMoveAccounts: PropTypes.func,
};

ApplicationsToolbar.defaultProps = {
  showInactive: false,
};

export default ApplicationsToolbar;
