import PropTypes from "prop-types";
import { styled } from "@material-ui/styles";
import { ReactComponent as Logo } from "../../images/logo.svg";
import AuxiliaryMenu from "./AuxiliaryMenu";

const StyledLogo = styled(Logo)(({ theme }) => ({
  flexGrow: 1,
  marginRight: `${theme.spacing(2)}px`,
  textAlign: "center",
}));

const StyledAuxilaryMenu = styled("div")(({ theme }) => ({
  // flexGrow: 1,
  // textAlign: "center",
}));

const MobilePrimaryContent = (props) => (
  <>
    <StyledAuxilaryMenu>
      {props.signedIn ? <AuxiliaryMenu /> : null}
    </StyledAuxilaryMenu>
    <StyledLogo width={110} height={44} />
  </>
);

MobilePrimaryContent.propTypes = {
  signedIn: PropTypes.bool,
};

MobilePrimaryContent.defaultProps = {
  signedIn: false,
};

export default MobilePrimaryContent;
