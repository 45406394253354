import PropTypes from "prop-types";

const UnlimitedCountIndicator = ({ applicationId, count }) => {
  if ((count ?? -1) < 0) {
    return null;
  }

  return (
    <p>{`Total ${applicationId ?? ""} ${
      count !== 1 ? "accounts" : "account"
    }: ${count}`}</p>
  );
};

UnlimitedCountIndicator.propTypes = {
  applicationid: PropTypes.string,
  count: PropTypes.number,
};

export default UnlimitedCountIndicator;
