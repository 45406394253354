import { useState } from "react";
import { useFetchKeys } from "../../hooks/useQueries";
import useResourceEditing from "../../hooks/useResourceEditing";
import Error from "../Common/Error";
import KeyDetailsDialog from "./KeyDetailsDialog";
import KeyHoldersDialog from "./KeyHoldersDialog";
import KeyList from "./KeyList";
import KeysToolbar from "./KeysToolbar";

const PopulatedKeyDetailsDialog = (props) => {
  const key = props.envKey;

  return (
    <KeyDetailsDialog
      open={props.open}
      keys={props.keys}
      keyId={key.keyId}
      name={key.name}
      value={key.value}
      error={props.error}
      onSave={props.onSave}
      onCancel={props.onCancel}
      onDelete={props.onDelete}
    />
  );
};

const Keys = () => {
  const [revealValues, setRevealValues] = useState(false);
  const [activeKeyId, setActiveKeyId] = useState();

  const { isLoading, isError, keys, error } = useFetchKeys();

  const {
    resourceState,
    editResource,
    addResource,
    updateResource,
    deleteResource,
    cancelResourceEditing,
  } = useResourceEditing("roles/keys");

  if (isError) {
    const message = `Oops! Failed to fetch keys. ${error.message}. Try refreshing the browser.`;

    return <Error message={message} />;
  }

  const handleEditKey = (keyId) => {
    const key = keys.find((key) => key.keyId === keyId);

    editResource(key);
  };

  const handleSaveKey = (key) => {
    const resource = {
      ...key,
      id: key.keyId,
    };

    if (!resourceState.data.keyId) {
      addResource.mutate(resource);
    } else {
      updateResource.mutate(resource);
    }
  };

  const handleDeleteKey = (keyId) => {
    deleteResource.mutate(keyId);
  };

  return (
    <div>
      <KeysToolbar
        revealValues={revealValues}
        onRevealValuesChange={setRevealValues}
        onAddKey={() => {
          editResource({});
        }}
      />
      <KeyList
        keys={keys}
        revealValues={revealValues}
        loading={isLoading}
        onEditKey={handleEditKey}
        onViewKeyHolders={setActiveKeyId}
      />
      {resourceState.data && (
        <PopulatedKeyDetailsDialog
          open={true}
          keys={keys}
          envKey={resourceState.data}
          error={resourceState.error}
          onSave={handleSaveKey}
          onCancel={cancelResourceEditing}
          onDelete={handleDeleteKey}
        />
      )}
      {activeKeyId && (
        <KeyHoldersDialog
          open={Boolean(activeKeyId)}
          keyId={activeKeyId}
          onClose={() => {
            setActiveKeyId();
          }}
        />
      )}
    </div>
  );
};

export default Keys;
