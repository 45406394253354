import { Table, TableContainer, TableHead, TableRow } from "@material-ui/core";
import {
  Block as BlockIcon,
  Edit as EditIcon,
  MeetingRoom as MeetingRoomIcon,
  VerifiedUser as VerifiedUserIcon,
} from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import { ReactComponent as RolesIcon } from "../../images/roles.svg";
import ActionButton from "../Common/ActionButton";
import DenseTableCell from "../Common/DenseTableCell";
import LoadingTableBody from "../Common/LoadingTableBody";

const StyledTable = styled(Table)({
  width: "100%",
  "& th": {
    whiteSpace: "nowrap",
  },
  "& .user-status": {
    width: 0,
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: "center",
    verticalAlign: "bottom",
  },
  "& .disabled-icon": {
    color: "red",
  },
  "& .verified-icon": {
    color: "green",
  },
  "& .backdoor:hover": {
    color: "red",

    "& svg": {
      fill: "red",
    },
  },
  "& .roles-icon": {
    width: 24,
  },
});

const AccountList = (props) => {
  const filterTerm = props.filterTerm?.toLowerCase();

  const accounts = !props.filterTerm
    ? props.accounts
    : props.accounts.filter(
        (account) =>
          account.email.toLowerCase().includes(filterTerm) ||
          account.lastName.toLowerCase().includes(filterTerm) ||
          account.firstName.toLowerCase().includes(filterTerm)
      );

  return (
    <TableContainer className={props.className}>
      <StyledTable size="small">
        <TableHead>
          <TableRow>
            <DenseTableCell />
            <DenseTableCell>Application</DenseTableCell>
            <DenseTableCell>ID</DenseTableCell>
            <DenseTableCell>e-mail</DenseTableCell>
            <DenseTableCell>Last name</DenseTableCell>
            <DenseTableCell>First name</DenseTableCell>
            <DenseTableCell>Last access time</DenseTableCell>
            <DenseTableCell />
          </TableRow>
        </TableHead>
        <LoadingTableBody
          loading={props.loading}
          colSpan={6}
          loadingMessage="Fetching accounts. Please wait."
        >
          {accounts
            .sort((a, b) => b.lastAccessTime - a.lastAccessTime)
            .map((account) => (
              <TableRow key={account.accountId}>
                <DenseTableCell className="user-status">
                  {account.isDisabled ? (
                    <BlockIcon className="disabled-icon" />
                  ) : account.isValidated ? (
                    <VerifiedUserIcon className="verified-icon" />
                  ) : null}
                </DenseTableCell>
                <DenseTableCell>{account.applicationId}</DenseTableCell>
                <DenseTableCell>{account.accountId}</DenseTableCell>
                <DenseTableCell>{account.email}</DenseTableCell>
                <DenseTableCell>{account.lastName}</DenseTableCell>
                <DenseTableCell>{account.firstName}</DenseTableCell>
                <DenseTableCell>
                  {account.lastAccessTime?.toLocaleString()}
                </DenseTableCell>
                <DenseTableCell>
                  <div>
                    <ActionButton
                      tooltip="Open backdoor"
                      className="backdoor"
                      onClick={() => {
                        props.onOpenBackdoor?.(account.accountId);
                      }}
                    >
                      <MeetingRoomIcon />
                    </ActionButton>
                    <ActionButton
                      tooltip="Edit account"
                      onClick={() => {
                        props.onEditAccount?.(account.accountId);
                      }}
                    >
                      <EditIcon />
                    </ActionButton>
                    <ActionButton
                      tooltip="Assign roles"
                      onClick={() => {
                        props.onAssignRoles?.(account.accountId);
                      }}
                    >
                      <RolesIcon className="roles-icon" />
                    </ActionButton>
                  </div>
                </DenseTableCell>
              </TableRow>
            ))}
        </LoadingTableBody>
      </StyledTable>
    </TableContainer>
  );
};

AccountList.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string.isRequired,
      applicationId: PropTypes.string.isRequired,
      lastAccessTime: PropTypes.instanceOf(Date),
    })
  ),
  filterTerm: PropTypes.string,
  loading: PropTypes.bool,
  onOpenBackdoor: PropTypes.func,
  onEditAccount: PropTypes.func,
  onAssignRoles: PropTypes.func,
};

AccountList.defaultProps = {
  accounts: [],
  loading: false,
};

export default AccountList;
