import { useState } from "react";
import PropTypes from "prop-types";
import { Table, TableContainer, TableHead, TableRow } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { styled } from "@material-ui/styles";
import ActionButton from "../Common/ActionButton";
import DenseTableCell from "../Common/DenseTableCell";
import LoadingTableBody from "../Common/LoadingTableBody";
import ActionPopover from "./ActionPopover";

const StyledTable = styled(Table)({
  width: "100%",
  "& th": {
    whiteSpace: "nowrap",
  },
});

const ActivityList = (props) => {
  const [anchorElement, setAnchorElement] = useState();
  const [selectedActions, setSelectedActions] = useState();

  const handleDetailsClose = () => {
    setAnchorElement(undefined);
  };

  return (
    <>
      <TableContainer>
        <StyledTable size="small">
          <TableHead>
            <TableRow>
              <DenseTableCell>Latest action time</DenseTableCell>
              <DenseTableCell>e-mail/Account ID</DenseTableCell>
              <DenseTableCell>Application</DenseTableCell>
              <DenseTableCell>Last name</DenseTableCell>
              <DenseTableCell>First name</DenseTableCell>
              <DenseTableCell># actions</DenseTableCell>
              <DenseTableCell />
            </TableRow>
          </TableHead>
          <LoadingTableBody
            loading={props.loading}
            colSpan={7}
            loadingMessage="Fetching activities. Please wait."
          >
            {props.activities
              .sort((a, b) => b.latestActionTime - a.latestActionTime)
              .map((activity, index) => (
                <TableRow key={`${activity.date} ${activity.accountId}`}>
                  <DenseTableCell>
                    {activity.latestActionTime.toLocaleString()}
                  </DenseTableCell>
                  <DenseTableCell>
                    {activity.email ?? activity.accountId}
                  </DenseTableCell>
                  <DenseTableCell>{activity.applicationId}</DenseTableCell>
                  <DenseTableCell>{activity.lastName}</DenseTableCell>
                  <DenseTableCell>{activity.firstName}</DenseTableCell>
                  <DenseTableCell align="right">
                    {activity.actions.length}
                  </DenseTableCell>
                  <DenseTableCell>
                    <ActionButton
                      tooltip="Show activity details"
                      onClick={(e) => {
                        setSelectedActions(props.activities[index].actions);
                        setAnchorElement(e.currentTarget);
                      }}
                    >
                      <InfoIcon />
                    </ActionButton>
                  </DenseTableCell>
                </TableRow>
              ))}
          </LoadingTableBody>
        </StyledTable>
      </TableContainer>
      <ActionPopover
        open={Boolean(anchorElement)}
        anchorElement={anchorElement}
        actions={selectedActions}
        onClose={handleDetailsClose}
      />
    </>
  );
};

ActivityList.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      applicationId: PropTypes.string,
      actions: PropTypes.array.isRequired,
    })
  ),
  loading: PropTypes.bool,
};

ActivityList.defaultProps = {
  activities: [],
  loading: false,
};

export default ActivityList;
