import { DialogContent } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  "& form > .MuiFormControl-root": {
    margin: theme.spacing(0.5, 0),
  },
}));

const DialogForm = (props) => (
  <StyledDialogContent>
    <form method="post" className={props.className}>
      {props.children}
    </form>
  </StyledDialogContent>
);

export default DialogForm;
