import MainMenuLink from "./MainMenuLink";

const MainMenuLinks = (props) => (
  <>
    <MainMenuLink to="/activity">Activity</MainMenuLink>
    <MainMenuLink to="/accounts">Accounts</MainMenuLink>
    <MainMenuLink to="/applications">Applications</MainMenuLink>
    <MainMenuLink to="/import">Import</MainMenuLink>
    <MainMenuLink to="/system-mail">System Mail</MainMenuLink>
    <MainMenuLink to="/mail-templates">Mail Templates</MainMenuLink>
    <MainMenuLink to="/roles">Roles</MainMenuLink>
    <MainMenuLink to="/keys">Keys</MainMenuLink>
  </>
);

export default MainMenuLinks;
