export const reshapeAccount = (account) => ({
  ...account,
  createMode: "simpleCreate",
  createdInApp: account.createdInApplicationId,
  password: account.newPassword,
  applicationLinks: [
    {
      applicationId: account.applicationId,
      userParameters: {
        textParam: account.parameters ?? "",
      },
    },
  ],
});

export const reshapeAccounts = (data) =>
  data.accounts.map((account) => ({
    ...account,
    lastAccessTime: account.lastAccess && new Date(account.lastAccess),
  }));

export const reshapeImportedAccounts = (data) =>
  data.outcomes.map((account) => ({
    ...account,
    status: account.resultStatus,
    parameters: account.params,
  }));
