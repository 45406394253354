import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  "& .MuiTypography-root": {
    marginTop: `${theme.spacing(1)}px`,
  },
}));

const DeleteKeyDialog = (props) => (
  <Dialog maxWidth="sm" fullWidth open={props.open}>
    <DialogTitle>Key deletion</DialogTitle>
    <StyledDialogContent>
      <Typography variant="body1">
        Deleting a key is irrevocable. If you choose to delete it, you will not
        be asked to confirm again.
      </Typography>
    </StyledDialogContent>
    <DialogActions>
      <Button color="primary" onClick={props.onCancel}>
        Cancel
      </Button>
      <Button color="secondary" onClick={props.onDelete}>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

DeleteKeyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DeleteKeyDialog;
