import PropTypes from "prop-types";
import { Fab, Tooltip } from "@material-ui/core";
import {
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
} from "@material-ui/icons";
import { styled } from "@material-ui/styles";

const PagerButton = (props) => (
  <Tooltip title={props.title}>
    <span>
      <Fab size="small" onClick={props.onClick} disabled={props.disabled}>
        {props.children}
      </Fab>
    </span>
  </Tooltip>
);

const StyledComponent = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "grid",
  gridAutoFlow: "column",
  gridColumnGap: `${theme.spacing(2)}px`,
  margin: theme.spacing(2, 0, 2, 1),
  marginLeft: `${theme.spacing(1)}px`,
  whiteSpace: "nowrap",
  width: "fit-content",
}));

const Pager = (props) => {
  const firstPage = props.page === 0;
  const lastPage = props.page >= props.pageCount - 1;

  return (
    <StyledComponent>
      <PagerButton
        title="First page"
        disabled={firstPage}
        onClick={props.onFirstPage}
      >
        <FirstPageIcon />
      </PagerButton>
      <PagerButton
        title="Previous page"
        disabled={firstPage}
        onClick={props.onPreviousPage}
      >
        <NavigateBeforeIcon />
      </PagerButton>

      <div>
        {props.page + 1} of {Math.max(props.pageCount, 1)}
      </div>

      <PagerButton
        title="Next page"
        disabled={lastPage}
        onClick={props.onNextPage}
      >
        <NavigateNextIcon />
      </PagerButton>
      <PagerButton
        title="Last page"
        disabled={lastPage}
        onClick={props.onLastPage}
      >
        <LastPageIcon />
      </PagerButton>
    </StyledComponent>
  );
};

Pager.propTypes = {
  page: PropTypes.number,
  pageCount: PropTypes.number,
  onFirstPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onNextPage: PropTypes.func,
  onLastPage: PropTypes.func,
};

Pager.defaultProps = {
  page: 0,
  pageCount: 0,
};

export default Pager;
