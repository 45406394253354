import PropTypes from "prop-types";
import { styled } from "@material-ui/styles";
import { ReactComponent as Logo } from "../../images/logo.svg";
import MainMenuLinks from "./MainMenuLinks";

const StyledLogo = styled(Logo)(({ theme }) => ({
  marginRight: `${theme.spacing(2)}px`,
}));

const StyledMainMenu = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));

const StyledMainMenuItems = styled("div")(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "column",
  gridColumnGap: `${theme.spacing(2)}px`,
  whiteSpace: "nowrap",
  width: "fit-content",
}));

const ComputerPrimaryContent = (props) => (
  <>
    <StyledLogo width={110} height={44} />
    <StyledMainMenu>
      {props.signedIn && (
        <StyledMainMenuItems>
          <MainMenuLinks />
        </StyledMainMenuItems>
      )}
    </StyledMainMenu>
  </>
);

ComputerPrimaryContent.propTypes = {
  signedIn: PropTypes.bool,
};

ComputerPrimaryContent.defaultProps = {
  signedIn: false,
};

export default ComputerPrimaryContent;
