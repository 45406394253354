import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AccountList from "./AccountList";
import Pager from "./Pager";

const PAGE_SIZE = 25;

const calculatePageCount = (accounts) => Math.ceil(accounts.length / PAGE_SIZE);

const PaginatedAccountList = ({ accounts, ...rest }) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [accounts]);

  const pageAccounts = accounts.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);

  const handleLastPage = () => {
    setPage(calculatePageCount(accounts) - 1);
  };

  return (
    <div>
      <Pager
        page={page}
        pageCount={calculatePageCount(accounts)}
        onFirstPage={() => {
          setPage(0);
        }}
        onPreviousPage={() => {
          setPage((page) => page - 1);
        }}
        onNextPage={() => {
          setPage((page) => page + 1);
        }}
        onLastPage={handleLastPage}
      />
      <AccountList accounts={pageAccounts} {...rest} />
    </div>
  );
};

PaginatedAccountList.propTypes = {
  accounts: PropTypes.array,
};

PaginatedAccountList.defaultProps = {
  accounts: [],
};

export default PaginatedAccountList;
