import PropTypes from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const StyledCircularProgress = styled(CircularProgress)({
  position: "absolute",
  top: "50%",
  bottom: "50%",
  marginTop: "-12px",
  marginBottom: "-12px",
});

const LoadingButton = ({
  children,
  loading,
  loadingIndicatorSize,
  loadingIndicatorColor,
  ...rest
}) => (
  <Button {...rest} disabled={rest.disabled || loading}>
    {loading && (
      <StyledCircularProgress size={24} color={loadingIndicatorColor} />
    )}
    {children}
  </Button>
);

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  loadingIndicatorColor: PropTypes.string,
};

LoadingButton.defaultProps = {
  loading: false,
  loadingIndicatorColor: "primary",
};

export default LoadingButton;
