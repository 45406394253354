import { useState } from "react";
import { fetchStatus } from "../api/api";
import useInterval from "./useInterval";

const CHECK_INTERVAL_SECONDS = 5;

const useCheckService = () => {
  const [lastSuccessfulCheck, setLastSuccessfulCheck] = useState();

  useInterval(async () => {
    try {
      const response = await fetchStatus();
      const data = response.data;
      setLastSuccessfulCheck(data?.siteEnabled ? new Date() : null);
    } catch {
      setLastSuccessfulCheck(undefined);
    }
  }, CHECK_INTERVAL_SECONDS * 1000);

  return lastSuccessfulCheck;
};

export default useCheckService;
