import PropTypes from "prop-types";
import { CircularProgress, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const StyledComponent = styled("div")({
  alignItems: "center",
  display: "flex",
});

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  marginRight: `${theme.spacing(1)}px`,
}));

const Loading = (props) => (
  <StyledComponent className={props.className}>
    <StyledCircularProgress size={20} color="primary" />
    <Typography variant="body1">{props.message}</Typography>
  </StyledComponent>
);

Loading.propType = {
  message: PropTypes.string,
};

Loading.defaultProps = {
  message: "Fetching data. Please wait.",
};

export default Loading;
