import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useState } from "react";
import { createGuid } from "../../utils";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";
import LockingDialogActions from "../Common/LockingDialogActions";
import PrivateTextInput from "../Common/PrivateTextInput";
import DeleteAccountDialog from "./DeleteAccountDialog";

const checkPasswordInvalidity = (password) => {
  const MINIMUM_PASSWORD_LENGTH = 6;

  if (!password || password.length < MINIMUM_PASSWORD_LENGTH) {
    return `Password must contain at least ${MINIMUM_PASSWORD_LENGTH} characters`;
  }

  if (/\s/.test(password)) {
    return "Password cannot contain whitespace";
  }

  return undefined;
};

export const generatePassword = (length) => {
  const VALID_CHARACTERS =
    "ABCDEFGHIJKLMNOPQRSTUVWYZ0123456789!@#$%^&*(){}[]=<>/,.|~?";

  return [...Array(length)]
    .map(() => {
      let character =
        VALID_CHARACTERS[Math.floor(Math.random() * VALID_CHARACTERS.length)];

      return Math.random() < 0.5 ? character : character.toLocaleLowerCase();
    })
    .join("");
};

const AccountDetailsDialog = (props) => {
  const creating = !props.email;
  const password = creating ? generatePassword(6) : undefined;

  const [applicationId, setApplicationId] = useState(props.applicationId);
  const [email, setEmail] = useState(props.email);
  const [enabled, setEnabled] = useState(!props.disabled);
  const [verified, setVerified] = useState(props.verified);
  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);
  const [organization, setOrganization] = useState(props.organization);
  const [parameters, setParameters] = useState(props.parameters);
  const [source, setSource] = useState(props.source);
  const [createdInApplicationId, setCreatedInApplicationId] = useState(
    props.createdInApplicationId
  );
  const [newPassword, setNewPassword] = useState(password);
  const [locked, setLocked] = useState(props.locked);
  const [confirmDeletionDialogOpen, setConfirmDeletionDialogOpen] = useState(
    false
  );

  const checkValidity = () =>
    email &&
    (email.includes("@") || email === "guest") &&
    firstName &&
    lastName &&
    (!checkPasswordInvalidity(newPassword) || (!creating && !newPassword));

  const createNewPasswordHelperText = () => {
    const invalidityMessage =
      checkPasswordInvalidity(newPassword) ?? "Password is valid";

    if (creating) {
      return invalidityMessage;
    }

    return !newPassword ? "Leave blank for no change" : invalidityMessage;
  };

  let applicationName;

  if (props.applications?.length && applicationId) {
    const application =
      props.applications.find(
        (application) => application.applicationId === applicationId
      ) ?? props.applications[0];

    applicationName = application.humanName;
  }

  let originalCreatedInApplication;

  if (props.applications?.length && createdInApplicationId) {
    originalCreatedInApplication = props.applications.find(
      (application) =>
        application.applicationId === props.createdInApplicationId
    );
  }

  let createdInApplicationName;

  if (props.applications?.length && createdInApplicationId) {
    const createdInApplication = props.applications.find(
      (application) => application.applicationId === createdInApplicationId
    );

    if (createdInApplication) {
      createdInApplicationName = createdInApplication.humanName;
    } else if (createdInApplicationId) {
      createdInApplicationName = createdInApplicationId;
    } else {
      createdInApplicationName = props.applications[0].humanName;
    }
  }

  const committing = props.saving || props.deleting;

  const handleResetTwoFactorAuthentication = () => {
    props.onSave?.({
      applicationId,
      newPassword: "2fa-reset",
      accountId: props.accountId,
    });
  };

  const handleDelete = () => {
    setConfirmDeletionDialogOpen(false);
    props.onDelete(props.accountId);
  };

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={props.open}>
        <DialogTitle>Account details</DialogTitle>
        <DialogForm>
          {props.error && (
            <FormControl>
              <Error message={props.error} />
            </FormControl>
          )}

          <FormControl fullWidth>
            <InputLabel htmlFor="application" shrink>
              Application
            </InputLabel>
            <Select
              id="application"
              native
              value={applicationId}
              disabled={!creating}
              onChange={(e) => {
                setApplicationId(e.target.value);
              }}
            >
              <option value="">Select application..</option>
              {props.applications.map((application) => {
                const applicationId = application.applicationId;

                return (
                  <option key={applicationId} value={applicationId}>
                    {applicationId}
                  </option>
                );
              })}
            </Select>
            <FormHelperText>{applicationName}</FormHelperText>
          </FormControl>

          <TextField
            label="e-mail"
            value={email ?? ""}
            required
            fullWidth
            helperText="Do not change the e-mail accidentally"
            disabled={locked}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={enabled}
                  onClick={() => {
                    setEnabled((enabled) => !enabled);
                  }}
                />
              }
              label="Enabled"
            />
            <FormHelperText>
              Disabling the account will prevent access
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={verified}
                  onClick={() => {
                    setVerified((verified) => !verified);
                  }}
                />
              }
              label="Verified"
              disabled={locked}
            />
          </FormControl>

          <TextField
            label="First name"
            value={firstName ?? ""}
            required
            fullWidth
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <TextField
            label="Last name"
            value={lastName ?? ""}
            required
            fullWidth
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          <TextField
            label="Organization"
            value={organization ?? ""}
            fullWidth
            onChange={(e) => {
              setOrganization(e.target.value);
            }}
          />
          <TextField
            label="Parameters"
            value={parameters ?? ""}
            fullWidth
            onChange={(e) => {
              setParameters(e.target.value);
            }}
          />
          <TextField
            label="Source"
            value={source ?? ""}
            fullWidth
            onChange={(e) => {
              setSource(e.target.value);
            }}
          />

          <FormControl fullWidth>
            <InputLabel htmlFor="created-in" shrink>
              Created in
            </InputLabel>
            <Select
              id="created-in"
              native
              value={createdInApplicationId}
              disabled={locked}
              onChange={(e) => {
                setCreatedInApplicationId(e.target.value);
              }}
            >
              <option value="">Select application..</option>
              {props.applications.map((application) => {
                const applicationId = application.applicationId;

                return (
                  <option key={applicationId} value={applicationId}>
                    {applicationId}
                  </option>
                );
              })}

              {!originalCreatedInApplication && (
                <option
                  key={props.createdInApplicationId}
                  value={props.createdInApplicationId}
                  disabled
                >
                  {props.createdInApplicationId}
                </option>
              )}
            </Select>
            <FormHelperText>{createdInApplicationName}</FormHelperText>
          </FormControl>

          <PrivateTextInput
            label="New password"
            value={newPassword ?? ""}
            required={creating}
            fullWidth
            helperText={createNewPasswordHelperText()}
            disabled={locked}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
          <FormControl
            style={{
              display: "block",
            }}
          >
            ID: {props.accountId}
          </FormControl>
          <FormControl
            style={{
              display: "block",
            }}
          >
            CKAN ID: {props.systemKeys.CkanUserId}
          </FormControl>
        </DialogForm>
        <LockingDialogActions locked={locked} onLockChange={setLocked}>
          <Button
            color="secondary"
            disabled={creating || locked || committing}
            onClick={handleResetTwoFactorAuthentication}
          >
            Reset 2FA
          </Button>
          <Button
            color="primary"
            disabled={committing}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            color="secondary"
            disabled={creating || locked || committing}
            loading={props.deleting}
            onClick={() => {
              setConfirmDeletionDialogOpen(true);
            }}
          >
            Delete
          </LoadingButton>
          <LoadingButton
            color="primary"
            disabled={!checkValidity() || committing}
            loading={props.saving}
            onClick={() => {
              props.onSave?.({
                applicationId,
                email: email || undefined,
                isDisabled: !enabled || undefined,
                isValidated: verified || undefined,
                firstName: firstName || undefined,
                lastName: lastName || undefined,
                organization: organization || undefined,
                parameters: parameters || undefined,
                source: source || undefined,
                createdInApplicationId: createdInApplicationId || undefined,
                newPassword: newPassword || undefined,
                accountId: props.accountId,
              });
            }}
          >
            Save
          </LoadingButton>
        </LockingDialogActions>
      </Dialog>

      {confirmDeletionDialogOpen && (
        <DeleteAccountDialog
          open={true}
          onCancel={() => {
            setConfirmDeletionDialogOpen(false);
          }}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

AccountDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  applicationId: PropTypes.string,
  email: PropTypes.string,
  isDisabled: PropTypes.bool,
  isValidated: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  organization: PropTypes.string,
  parameters: PropTypes.string,
  source: PropTypes.string,
  createdInApplicationId: PropTypes.string,
  systemKeys: PropTypes.object,
  accountId: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  saving: PropTypes.bool,
  deleting: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

AccountDetailsDialog.defaultProps = {
  isDisabled: false,
  isValidated: true,
  systemKeys: {},
  accountId: createGuid(),
  locked: true,
  saving: false,
  deleting: false,
};

export default AccountDetailsDialog;
