import {
  Fab,
  FormControlLabel,
  Switch,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";

const KeysToolbar = (props) => (
  <StyledToolbar>
    <Tooltip title="Create new key">
      <Fab color="primary" size="small" onClick={props.onAddKey}>
        <AddIcon />
      </Fab>
    </Tooltip>

    <FormControlLabel
      control={<Switch color="primary" />}
      label="Reveal values"
      checked={props.revealValues}
      onChange={(e) => {
        props.onRevealValuesChange?.(e.target.checked);
      }}
    />
  </StyledToolbar>
);

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "column",
  gridColumnGap: `${theme.spacing(3)}px`,
  width: "fit-content",
}));

KeysToolbar.propTypes = {
  revealValues: PropTypes.bool,
  onAddKey: PropTypes.func,
  onRevealValuesChange: PropTypes.func,
};

KeysToolbar.defaultProps = {
  revealValues: false,
};

export default KeysToolbar;
