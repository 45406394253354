import PropTypes from "prop-types";
import { TextField, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const StyledComponent = styled("div")(({ theme }) => ({
  "& .MuiFormControl-root": {
    margin: theme.spacing(1, 0),
  },
  "& .MuiFormControl-root.data": {
    margin: theme.spacing(2, 0),
  },
}));

const AccountData = (props) => (
  <StyledComponent>
    <Typography variant="body2" color="textSecondary">
      Field specifiers should be: email, password, first, last, paramtext,
      source. Last name will be determined from first, if not present. Passwords
      may be blank. Text after a semi-colon is ignored (e.g. for comments).
    </Typography>

    <TextField
      label="Fields"
      value={props.fields ?? ""}
      fullWidth
      style={{
        display: "none",
      }}
      onChange={(e) => {
        props.onFieldsChange?.(e.target.value);
      }}
    />

    <TextField
      multiline
      variant="outlined"
      rows={10}
      label="Accounts"
      value={props.data || ""}
      required
      fullWidth
      className="data"
      onChange={(e) => {
        props.onDataChange?.(e.target.value);
      }}
    />
  </StyledComponent>
);

AccountData.propTypes = {
  fields: PropTypes.string,
  data: PropTypes.string,
  onFieldsChange: PropTypes.func,
  onDataChange: PropTypes.func,
};

export default AccountData;
