import { Typography } from "@material-ui/core";
import IFrame from "../Common/IFrame";
import { MAIL_TEMPLATE_PREVIEW_LOCAL_STORAGE_KEY } from "../../constants";
import useLocalStorage from "../../hooks/useLocalStorage";

const MailTemplatePreview = (props) => {
  const [mailTemplatePreview] = useLocalStorage(
    MAIL_TEMPLATE_PREVIEW_LOCAL_STORAGE_KEY
  );

  return (
    <div>
      <Typography variant="h6">Mail template preview</Typography>
      <IFrame title="Mail template preview" width="100%" autoSizeHeight>
        <div
          dangerouslySetInnerHTML={{
            __html: mailTemplatePreview,
          }}
        />
      </IFrame>
    </div>
  );
};

export default MailTemplatePreview;
