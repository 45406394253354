import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import DialogForm from "../Common/DialogForm";
import LoadingButton from "../Common/LoadingButton";
import SelectList from "../Common/SelectList";
import { useFetchKeys } from "../../hooks/useQueries";
import { useState } from "react";

const StyledDialogForm = styled(DialogForm)(() => ({
  "& .MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
  },
}));

const AssignKeysDialog = (props) => {
  const [assignedKeyIds, setAssignedKeyIds] = useState(props.assignedKeyIds);

  const { keys } = useFetchKeys();

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Assign keys to {props.role?.roleId}</DialogTitle>
      <StyledDialogForm>
        <SelectList
          choices={keys
            ?.sort(
              (a, b) =>
                a.name.localeCompare(b.name) || a.keyId.localeCompare(b.keyId)
            )
            .map((key) => ({
              id: key.keyId,
              label: `${key.keyId}/${key.name}`,
            }))}
          chosenIds={assignedKeyIds}
          onChange={(value) => {
            setAssignedKeyIds(value);
          }}
        />
      </StyledDialogForm>
      <DialogActions>
        <Button color="primary" onClick={props.onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          loading={false}
          onClick={() => {
            props.onSave?.(assignedKeyIds);
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

AssignKeysDialog.propTypes = {
  open: PropTypes.bool,
  role: PropTypes.shape({
    roleId: PropTypes.string.isRequired,
  }),
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      keyId: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  assignedKeyIds: PropTypes.arrayOf(PropTypes.string),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

AssignKeysDialog.defaultProps = {
  open: false,
  keys: [],
  assignedKeyIds: [],
};

export default AssignKeysDialog;
