import axios from "axios";
import fileDownload from "js-file-download";

const AUTHENTICATION_APPLICATION_ID = "--admin--";

const baseUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVICE_URL}/${process.env.REACT_APP_AUTHENTICATION_SERVICE_VERSION}/api/`;

const service = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

const download = async (url, filename, options = {}) => {
  const response = await fetchData(url, options);

  fileDownload(response.data, filename);
};

export const deleteData = (url, options) => service.delete(url, options);

export const downloadAccountsCsv = (filename, accountIds, applicationId) => {
  download("/accounts/csv", filename, {
    params: {
      accountIds,
      applicationId,
    },
  });
};

export const downloadImportedAccountsCsv = (filename, csvDownloadId) => {
  download("/accounts/csv", filename, {
    params: {
      importId: csvDownloadId,
    },
  });
};

export const downloadStatisticReport = (filename, statistic) => {
  download("/queries/results", filename, {
    params: {
      queryId: statistic,
    },
  });
};

export const fetchData = (url, options) => service.get(url, options);

export const fetchStatus = () => fetchData("/status");

export const postData = (url, data, options) =>
  service.post(url, data, options);

export const putData = (url, data, options) => service.put(url, data, options);

export const sendMailTemplateTestMessage = (mailTemplateId) =>
  service.post(`/mail-templates/test/${mailTemplateId}`);

export const sendSystemMailTestMessage = (mailDestinationId) =>
  service.post(`/mail-destinations/test/${mailDestinationId}`);

export const signIn = (email, password, rememberMe, authenticationToken) =>
  service.post("/session", {
    applicationId: AUTHENTICATION_APPLICATION_ID,
    email,
    password,
    rememberMe,
    token: authenticationToken,
  });

export const signOut = (implicit) =>
  service.delete("/session", {
    params: {
      implicit: implicit ?? false,
    },
  });

const api = {
  deleteData,
  downloadAccountsCsv,
  downloadImportedAccountsCsv,
  downloadStatisticReport,
  fetchData,
  fetchStatus,
  postData,
  putData,
  sendSystemMailTestMessage,
  signIn,
  signOut,
};

export default api;
