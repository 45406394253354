import { isValidElement } from "react";
import PropTypes from "prop-types";
import { Switch, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const Label = ({ children }) =>
  isValidElement(children) ? (
    children
  ) : (
    <Typography variant="body1">{children}</Typography>
  );

const StyledComponent = styled("div")({
  alignItems: "center",
  display: "grid",
  gridAutoFlow: "column",
  width: "fit-content",
});

const BinarySwitch = (props) => (
  <StyledComponent>
    <Label>{props.falseLabel}</Label>
    <div>
      <Switch
        checked={props.checked}
        onChange={(e) => {
          props.onChange?.(e.target.checked);
        }}
      />
    </div>
    <Label>{props.trueLabel}</Label>
  </StyledComponent>
);

BinarySwitch.propTypes = {
  falseLabel: PropTypes.node,
  trueLabel: PropTypes.node,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

BinarySwitch.defaultProps = {
  falseLabel: "Off",
  trueLabel: "On",
  checked: false,
};

export default BinarySwitch;
