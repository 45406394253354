import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core";
import Banner from "../Common/Banner";

const ApplicationBanners = (props) => {
  const theme = useTheme();

  return (
    <>
      {props.showSplashMessage && props.splashMessage ? (
        <Banner
          message={props.splashMessage}
          color={theme.palette.warning.light}
        />
      ) : null}

      {props.children}

      {props.footerMessage && (
        <Banner
          message={props.footerMessage}
          color={theme.palette.info.light}
        />
      )}
    </>
  );
};

ApplicationBanners.propTypes = {
  splashMessage: PropTypes.string,
  footerMessage: PropTypes.string,
  showSplashMessage: PropTypes.bool,
};

ApplicationBanners.defaultProps = {
  showSplashMessage: false,
};

export default ApplicationBanners;
