import { TableCell } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const StyledTableCell = styled(TableCell)({
  paddingBottom: 0,
  paddingTop: 0,
});

const DenseTableCell = (props) => <StyledTableCell {...props} />;

export default DenseTableCell;
