import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { downloadImportedAccountsCsv } from "../../api/api";
import ImportedAccountList from "./ImportedAccountList";

const StyledButton = styled(Button)(({ theme }) => ({
  display: "inline-block",
  marginTop: `${theme.spacing(2)}px`,
}));

const ImportReview = (props) => (
  <>
    <Typography variant="h6">Review</Typography>
    <Typography variant="body1">{props.summary}</Typography>
    <ImportedAccountList accounts={props.accounts} />

    {props.csvDownloadId && (
      <StyledButton
        variant="contained"
        color="primary"
        onClick={() => {
          downloadImportedAccountsCsv("accounts.csv", props.csvDownloadId);
        }}
      >
        Download CSV
      </StyledButton>
    )}
  </>
);

ImportReview.propTypes = {
  summary: PropTypes.string.isRequired,
  accounts: PropTypes.array,
  csvDownloadId: PropTypes.string,
};

export default ImportReview;
