import PropTypes from "prop-types";
import { TableBody, TableRow } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import DenseTableCell from "./DenseTableCell";
import Loading from "./Loading";

const StyledLoading = styled(Loading)({
  margin: "auto",
  whiteSpace: "nowrap",
  width: "fit-content",
});

const LoadingTableRow = (props) => (
  <TableRow>
    <DenseTableCell colSpan={props.colSpan}>
      <StyledLoading message={props.loadingMessage} />
    </DenseTableCell>
  </TableRow>
);

const LoadingTableBody = ({ loading, children, ...rest }) => (
  <TableBody>{loading ? <LoadingTableRow {...rest} /> : children}</TableBody>
);

LoadingTableBody.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  colSpan: PropTypes.number.isRequired,
  loadingMessage: PropTypes.string,
};

LoadingTableBody.defaultProps = {
  loading: false,
};

export default LoadingTableBody;
