import PropTypes from "prop-types";
import { DialogActions } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import LockSwitch from "../Common/LockSwitch";

const StyledLeftDialogActionsWrapper = styled("div")({
  flex: "1 0 0",
});

const LockingDialogActions = (props) => (
  <DialogActions>
    <StyledLeftDialogActionsWrapper>
      <LockSwitch locked={props.locked} onChange={props.onLockChange} />
    </StyledLeftDialogActionsWrapper>
    {props.children}
  </DialogActions>
);

LockingDialogActions.propTypes = {
  locked: PropTypes.bool,
  onLockChange: PropTypes.func,
};

LockingDialogActions.defaultProps = {
  locked: true,
};

export default LockingDialogActions;
