import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { putData } from "../../api/api";
import useMutation from "../../hooks/useMutation";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";
import LockingDialogActions from "../Common/LockingDialogActions";
import PrivateTextInput from "../Common/PrivateTextInput";
import MoveActions from "./MoveActions";

const CONFIRMATION_CODE = "move";

const StyledDialogForm = styled(DialogForm)(({ theme }) => ({
  "& legend": {
    marginBottom: `${theme.spacing(0.5)}px`,
  },
  "& .MuiRadio-root": {
    paddingBottom: `${theme.spacing(0.5)}px`,
    paddingTop: `${theme.spacing(0.5)}px`,
  },
  "& > fieldset.MuiFormControl-root": {
    margin: theme.spacing(2, 0, 0.5),
  },
}));

export const MoveAccountsDialogPresenter = (props) => {
  const applications = props.applications.sort((a, b) =>
    a.applicationId.localeCompare(b.applicationId)
  );

  const [fromApplicationId, setFromApplicationId] = useState(
    applications.length ? applications[0].applicationId : undefined
  );

  const toApplications = props.applications
    .filter(({ applicationId }) => applicationId !== fromApplicationId)
    .sort((a, b) => a.applicationId.localeCompare(b.applicationId));

  const [toApplicationId, setToApplicationId] = useState(
    toApplications.length ? toApplications[0].applicationId : undefined
  );
  const [parameterPriority, setParameterPriority] = useState(
    "from application"
  );
  const [permission, setPermission] = useState();
  const [testMode, setTestMode] = useState(false);
  const [locked, setLocked] = useState(props.locked);

  const fromApplication = props.applications.find(
    (application) => application.applicationId === props.fromApplicationId
  );

  const toApplication = props.applications.find(
    (application) => application.applicationId === toApplicationId
  );

  const checkValidity = () => permission;

  const createConfiguration = (testMode) => ({
    fromApplicationId,
    toApplicationId,
    parameterPriority,
    permission,
    testMode,
  });

  const handleFromApplicationChange = (applicationId) => {
    setFromApplicationId(applicationId);
  };

  const handleToApplicationChange = (applicationId) => {
    setToApplicationId(applicationId);
  };

  const handleTest = () => {
    setTestMode(true);

    props.onMove?.(createConfiguration(true));
  };

  const handleMove = () => {
    setTestMode(false);

    props.onMove?.(createConfiguration(false));
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Move accounts</DialogTitle>
      <StyledDialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        <FormControl fullWidth>
          <InputLabel htmlFor="from-application">From application</InputLabel>
          <Select
            id="from-application"
            native
            value={fromApplicationId}
            onChange={(e) => {
              handleFromApplicationChange(e.target.value);
            }}
          >
            {applications.map((application) => {
              const applicationId = application.applicationId;

              return (
                <option key={applicationId} value={applicationId}>
                  {applicationId}
                </option>
              );
            })}
          </Select>
          <FormHelperText>{fromApplication?.humanName}</FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="to-application">To application</InputLabel>
          <Select
            id="to-application"
            native
            value={toApplicationId}
            onChange={(e) => {
              handleToApplicationChange(e.target.value);
            }}
          >
            {toApplications.map((application) => {
              const applicationId = application.applicationId;

              return (
                <option key={applicationId} value={applicationId}>
                  {applicationId}
                </option>
              );
            })}
          </Select>
          <FormHelperText>{toApplication?.humanName}</FormHelperText>
        </FormControl>

        <FormControl component="fieldset">
          <FormLabel component="legend">Parameter priority</FormLabel>
          <RadioGroup
            value={parameterPriority}
            onChange={(e) => {
              setParameterPriority(e.target.value);
            }}
          >
            <FormControlLabel
              value="from application"
              control={<Radio />}
              label={`Priority to ${fromApplicationId} parameters`}
            />
            <FormControlLabel
              value="to application"
              control={<Radio />}
              label={`Priority to ${toApplicationId} parameters`}
            />
          </RadioGroup>
          <FormHelperText>
            Priority determines what happens to an account's parameters if it
            already exists
          </FormHelperText>
        </FormControl>

        <PrivateTextInput
          label="Confirmation"
          value={permission ?? ""}
          required
          fullWidth
          helperText={`Enter '${CONFIRMATION_CODE}' to confirm move`}
          onChange={(e) => {
            setPermission(e.target.value);
          }}
        />

        {props.actions && props.actions.length && (
          <MoveActions actions={props.actions} />
        )}
      </StyledDialogForm>
      <LockingDialogActions locked={locked} onLockChange={setLocked}>
        <Button color="primary" onClick={props.onCancel}>
          Cancel
        </Button>
        <LoadingButton
          color="secondary"
          disabled={locked || !checkValidity()}
          loading={!testMode && props.saving}
          onClick={handleMove}
        >
          Move
        </LoadingButton>
        <LoadingButton
          color="primary"
          disabled={!checkValidity()}
          loading={testMode && props.saving}
          onClick={handleTest}
        >
          Test
        </LoadingButton>
      </LockingDialogActions>
    </Dialog>
  );
};

MoveAccountsDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      applicationId: PropTypes.string.isRequired,
      humanName: PropTypes.string,
    })
  ).isRequired,
  locked: PropTypes.bool,
  onCancel: PropTypes.func,
  onMove: PropTypes.func,
};

MoveAccountsDialogPresenter.defaultProps = {
  applications: [],
  locked: true,
};

const MoveAccountsDialog = (props) => {
  const [actions, setActions] = useState();

  const mutation = useMutation(
    (resource) => putData("/applications/moveusers", resource),
    {
      onMutate: () => {
        setActions(undefined);
      },
      onSuccess: (data) => {
        if (data?.data.testMode) {
          setActions(data?.data.actions);
        } else {
          props.onClose();
        }
      },
    }
  );

  const handleMove = (configuration) => {
    const resource = {
      ...configuration,
      priorityToFrom: configuration.parameterPriority === "to application",
    };

    mutation.mutate(resource);
  };

  return (
    <MoveAccountsDialogPresenter
      {...props}
      saving={mutation.isLoading}
      actions={actions}
      error={mutation.error?.response.data.message}
      onMove={handleMove}
      onCancel={props.onClose}
    />
  );
};

MoveAccountsDialog.propTypes = {
  onClose: PropTypes.func,
};

export default MoveAccountsDialog;
