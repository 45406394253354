import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  TextField,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import DialogForm from "../Common/DialogForm";
import Error from "../Common/Error";
import LoadingButton from "../Common/LoadingButton";
import LockingDialogActions from "../Common/LockingDialogActions";

const parseEmailDestinations = (text) =>
  text
    ? text.split(/\r?\n/).filter((emailDestination) => emailDestination)
    : [];

const StyledDialogForm = styled(DialogForm)(({ theme }) => ({
  "& .MuiFormControl-root.email-addresses": {
    margin: theme.spacing(2, 0),
  },
}));

const DestinationDetailsDialog = (props) => {
  const [destinationId, setDestinationId] = useState(props.destinationId);
  const [purpose, setPurpose] = useState(props.purpose);
  const [emailDestinations, setEmailDestinations] = useState(
    props.emailDestinations.join("\n")
  );
  const [fromName, setFromName] = useState(props.fromName);
  const [fromEmail, setFromEmail] = useState(props.fromEmail);
  const [imageUrl, setImageUrl] = useState(props.imageUrl);
  const [locked, setLocked] = useState(props.locked);

  const checkValidity = () =>
    destinationId && emailDestinations && fromName && fromEmail;

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Destination details</DialogTitle>
      <StyledDialogForm>
        {props.error && (
          <FormControl>
            <Error message={props.error} />
          </FormControl>
        )}

        <TextField
          label="Destination code"
          value={destinationId ?? ""}
          required
          fullWidth
          helperText={`
            This is the code that an e-mail sending application will use with the API to select this list. 
            Set the name to DELETE to remove it from the disk
          `}
          disabled={locked}
          onChange={(e) => {
            setDestinationId(e.target.value);
          }}
        />
        <TextField
          label="Destination purpose"
          value={purpose ?? ""}
          fullWidth
          onChange={(e) => {
            setPurpose(e.target.value);
          }}
        />
        <TextField
          multiline
          variant="outlined"
          rows={10}
          label="e-mail destinations"
          value={emailDestinations}
          required
          fullWidth
          helperText="e-mail addresses are not validated. Please check they are well-formatted."
          className="email-addresses"
          onChange={(e) => {
            setEmailDestinations(e.target.value);
          }}
        />
        <TextField
          label="From name"
          value={fromName ?? ""}
          required
          fullWidth
          onChange={(e) => {
            setFromName(e.target.value);
          }}
        />
        <TextField
          label="From e-mail"
          value={fromEmail ?? ""}
          required
          fullWidth
          onChange={(e) => {
            setFromEmail(e.target.value);
          }}
        />
        <TextField
          label="Image URL"
          value={imageUrl ?? ""}
          fullWidth
          helperText="The URL can be relative or an absolute link to another server"
          onChange={(e) => {
            setImageUrl(e.target.value);
          }}
        />
      </StyledDialogForm>
      <LockingDialogActions locked={locked} onLockChange={setLocked}>
        <Button
          color="primary"
          disabled={props.saving}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          disabled={!checkValidity()}
          loading={props.saving}
          onClick={() => {
            props.onSave?.({
              destinationId,
              purpose,
              emailDestinations: parseEmailDestinations(emailDestinations),
              fromName,
              fromEmail,
              imageUrl,
            });
          }}
        >
          Save
        </LoadingButton>
      </LockingDialogActions>
    </Dialog>
  );
};

DestinationDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  destinationId: PropTypes.string,
  purpose: PropTypes.string,
  emailDestinations: PropTypes.arrayOf(PropTypes.string),
  fromName: PropTypes.string,
  fromEmail: PropTypes.string,
  imageUrl: PropTypes.string,
  locked: PropTypes.bool,
  saving: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

DestinationDetailsDialog.defaultProps = {
  emailDestinations: [],
  locked: true,
  saving: false,
};

export default DestinationDetailsDialog;
