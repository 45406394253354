import Configuration from "./Configuration";
import Main from "./Main";

const App = () => (
  <Configuration>
    <Main />
  </Configuration>
);

export default App;
