import { useState } from "react";
import PropTypes from "prop-types";
import { Popover } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { CompactPicker as Picker } from "react-color";

const DEFAULT_WIDTH = 50;
const GOLDEN_RATIO = 1.61803398875;

const StyledSwatch = styled("div")((props) => ({
  backgroundColor: props.color,
  border: "1px solid black",
  height: `${props.height}px`,
  width: `${props.width}px`,
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  marginTop: `${theme.spacing(1)}px`,
}));

const ColorPicker = (props) => {
  const [color, setColor] = useState(props.color);
  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  const handleChangeComplete = (color) => {
    setColor(color.hex);

    props.onChange?.(color.hex);
  };

  return (
    <div className="color-picker">
      <StyledSwatch
        width={props.width}
        height={props.height}
        color={color}
        onClick={(e) => {
          setAnchorElement(e.currentTarget);
          setOpen((open) => !open);
        }}
      />
      <StyledPopover
        open={open}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Picker color={color} onChangeComplete={handleChangeComplete} />
      </StyledPopover>
    </div>
  );
};

ColorPicker.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  onChange: PropTypes.func,
};

ColorPicker.defaultProps = {
  width: DEFAULT_WIDTH,
  height: DEFAULT_WIDTH / GOLDEN_RATIO,
  color: "#000",
};

export default ColorPicker;
