import groupBy from "lodash/groupBy";
import keyBy from "lodash/groupBy";
import max from "lodash/max";
import moment from "moment";

export const reshapeActivities = (data) => {
  let { userActivity: activities, accounts } = data;

  activities = activities.map((activity) => ({
    ...activity,
    date: moment(activity.activityTime).format("YYYY-MM-DD"),
  }));

  activities = groupBy(
    activities,
    (activity) => `${activity.date},${activity.accountId}`
  );

  activities = Object.entries(activities).map(([key, value]) => ({
    date: value[0].date,
    accountId: value[0].accountId,
    actions: value.map((activity) => ({
      type: activity.activityType,
      text: activity.activityText,
      time: new Date(activity.activityTime),
    })),
  }));

  activities = activities.map((activity) => ({
    latestActionTime: max(activity.actions.map((action) => action.time)),
    ...activity,
  }));

  accounts = keyBy(accounts, "accountId");

  activities = activities.map((activity) => {
    const account = accounts[activity.accountId]?.[0];

    return {
      ...activity,
      firstName: account?.firstName,
      lastName: account?.lastName,
      email: account?.email,
      applicationId: account?.applicationId,
    };
  });

  return activities;
};
