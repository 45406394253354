import { useSnackbar } from "notistack";
import { sendSystemMailTestMessage } from "../../api/api";
import Error from "../Common/Error";
import { reshapeMailDestination } from "../../data/reshapeMailDestinations";
import { useFetchMailDestinations } from "../../hooks/useQueries";
import useResourceEditing from "../../hooks/useResourceEditing";
import DestinationDetailsDialog from "./DestinationDetailsDialog";
import SystemMailList from "./SystemMailList";
import SystemMailToolbar from "./SystemMailToolbar";

const PopulatedDestinationDetailsDialog = (props) => {
  const mailDestination = props.mailDestination;

  return (
    <DestinationDetailsDialog
      open={props.open}
      destinationId={mailDestination.destinationId}
      purpose={mailDestination.purpose}
      emailDestinations={mailDestination.emailDestinations}
      fromName={mailDestination.fromName}
      fromEmail={mailDestination.fromEmail}
      imageUrl={mailDestination.imageUrl}
      locked={!!mailDestination.destinationId}
      saving={props.saving}
      onSave={props.onSave}
      onCancel={props.onCancel}
    />
  );
};

const SystemMail = () => {
  const {
    isLoading,
    isError,
    mailDestinations,
    error,
  } = useFetchMailDestinations();

  const {
    resourceState,
    editResource,
    addResource,
    updateResource,
    cancelResourceEditing,
  } = useResourceEditing("mail-destinations");

  const { enqueueSnackbar } = useSnackbar();

  if (isError) {
    const message = `Oops! Failed to fetch mail destinations. ${error.message}. Try refreshing the browser.`;

    return <Error message={message} />;
  }

  const handleEditMailDesintation = (mailDestinationId) => {
    const mailDestination = mailDestinations.find(
      (mailDestination) => mailDestination.destinationId === mailDestinationId
    );

    editResource(mailDestination);
  };

  const handleSaveMailDestination = (mailDestination) => {
    const resource = reshapeMailDestination(mailDestination);

    if (!resourceState.data.destinationId) {
      addResource.mutate(resource);
    } else {
      updateResource.mutate({
        ...resource,
        id: resource.destinationId,
      });
    }
  };

  const handleSendTestMessage = async (mailDestinationId) => {
    try {
      await sendSystemMailTestMessage(mailDestinationId);

      enqueueSnackbar("Test message sent.", {
        variant: "info",
      });
    } catch (error) {
      enqueueSnackbar("Failed to send test message", {
        variant: "error",
      });

      console.error(error);
    }
  };

  return (
    <div>
      <SystemMailToolbar
        onAddDesitination={() => {
          editResource({});
        }}
      />
      <SystemMailList
        mailDestinations={mailDestinations}
        loading={isLoading}
        onEditMailDestination={handleEditMailDesintation}
        onSendTestMessage={handleSendTestMessage}
      />
      {resourceState.data && (
        <PopulatedDestinationDetailsDialog
          open={true}
          mailDestination={resourceState.data}
          saving={resourceState.saving}
          onSave={handleSaveMailDestination}
          onCancel={cancelResourceEditing}
        />
      )}
    </div>
  );
};

export default SystemMail;
