import PropTypes from "prop-types";
import {
  Popover,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DenseTableCell from "../Common/DenseTableCell";

const ActionPopover = (props) => (
  <Popover
    open={props.open}
    anchorEl={props.anchorElement}
    onClose={props.onClose}
  >
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <DenseTableCell>Time</DenseTableCell>
            <DenseTableCell>Action</DenseTableCell>
            <DenseTableCell>Description</DenseTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.actions
            .sort((a, b) => b.time - a.time)
            .map((action, index) => (
              <TableRow key={action.time.getTime()}>
                <DenseTableCell>
                  {action.time.toLocaleTimeString()}
                </DenseTableCell>
                <DenseTableCell>{action.type}</DenseTableCell>
                <DenseTableCell>{action.text}</DenseTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Popover>
);

ActionPopover.propTypes = {
  open: PropTypes.bool,
  anchorElement: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      time: PropTypes.instanceOf(Date).isRequired,
      text: PropTypes.string,
    })
  ),
  onClose: PropTypes.func,
};

ActionPopover.defaultProps = {
  open: false,
  actions: [],
};

export default ActionPopover;
