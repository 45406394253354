import { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import DialogForm from "../Common/DialogForm";
import LockingDialogActions from "../Common/LockingDialogActions";
import { MAIL_TEMPLATE_PREVIEW_LOCAL_STORAGE_KEY } from "../../constants";
import useLocalStorage from "../../hooks/useLocalStorage";

const StyledDialogForm = styled(DialogForm)(({ theme }) => ({
  "& .MuiFormControl-root.template-text": {
    margin: theme.spacing(2, 0),
  },
}));

export const TemplateDetailsDialogPresenter = (props) => {
  const [templateId, setTemplateId] = useState(props.templateId);
  const [enabled, setEnabled] = useState(props.enabled);
  const [subject, setSubject] = useState(props.subject);
  const [internalPurpose, setInternalPurpose] = useState(props.internalPurpose);
  const [templateText, setTemplateText] = useState(props.templateText);
  const [locked, setLocked] = useState(props.locked);

  const checkValidity = () => templateId && subject && templateText;

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Template details</DialogTitle>
      <StyledDialogForm>
        <TextField
          label="ID"
          value={templateId ?? ""}
          required
          fullWidth
          disabled={locked}
          onChange={(e) => {
            setTemplateId(e.target.value);
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={enabled}
              onClick={() => {
                setEnabled((enabled) => !enabled);
              }}
            />
          }
          label="Enabled"
        />
        <TextField
          label="Purpose"
          value={internalPurpose ?? ""}
          required
          fullWidth
          helperText="Internal use only. Not shown to users."
          onChange={(e) => {
            setInternalPurpose(e.target.value);
          }}
        />
        <TextField
          label="Subject"
          value={subject ?? ""}
          required
          fullWidth
          onChange={(e) => {
            setSubject(e.target.value);
          }}
        />
        <TextField
          multiline
          variant="outlined"
          rows={10}
          label="Template text"
          value={templateText}
          required
          fullWidth
          helperText="Variables are surrounded by % symbols (e.g. %HOST%)."
          className="template-text"
          onChange={(e) => {
            setTemplateText(e.target.value);
          }}
        />
      </StyledDialogForm>
      <LockingDialogActions locked={locked} onLockChange={setLocked}>
        <Button color="primary" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props.onPreview?.(templateText);
          }}
        >
          Preview
        </Button>
        <Button
          color="primary"
          disabled={!checkValidity()}
          onClick={() => {
            props.onSave?.({
              templateId,
              enabled,
              internalPurpose,
              subject,
              templateText,
            });
          }}
        >
          Save
        </Button>
      </LockingDialogActions>
    </Dialog>
  );
};

TemplateDetailsDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  templateId: PropTypes.string,
  enabled: PropTypes.bool,
  subject: PropTypes.string,
  templateText: PropTypes.string,
  locked: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

TemplateDetailsDialogPresenter.defaultProps = {
  enabled: true,
  locked: true,
};

const TemplateDetailsDialog = (props) => {
  const [, setMailTemplatePreview] = useLocalStorage(
    MAIL_TEMPLATE_PREVIEW_LOCAL_STORAGE_KEY
  );

  const handlePreview = (templateText) => {
    setMailTemplatePreview(templateText);

    window.open("/#/mail-templates/preview", "_blank");
  };

  return (
    <TemplateDetailsDialogPresenter {...props} onPreview={handlePreview} />
  );
};

export default TemplateDetailsDialog;
