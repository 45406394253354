import PropTypes from "prop-types";
import { Table, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import ActionButton from "../Common/ActionButton";
import { Edit as EditIcon, Send as SendIcon } from "@material-ui/icons";
import DenseTableCell from "../Common/DenseTableCell";
import EnabledIndicator from "../Common/EnabledIndicator";
import LoadingTableBody from "../Common/LoadingTableBody";

const StyledTable = styled(Table)({
  width: "100%",
});

const MailTemplateList = (props) => (
  <TableContainer>
    <StyledTable size="small">
      <TableHead>
        <TableRow>
          <DenseTableCell />
          <DenseTableCell>ID</DenseTableCell>
          <DenseTableCell>Purpose</DenseTableCell>
          <DenseTableCell>Subject</DenseTableCell>
          <DenseTableCell />
        </TableRow>
      </TableHead>
      <LoadingTableBody
        loading={props.loading}
        colSpan={5}
        loadingMessage="Fetching mail templates. Please wait."
      >
        {props.templates.map((template) => (
          <TableRow key={template.templateId}>
            <DenseTableCell>
              <EnabledIndicator enabled={template.enabled} color="primary" />
            </DenseTableCell>
            <DenseTableCell>{template.templateId}</DenseTableCell>
            <DenseTableCell>{template.internalPurpose}</DenseTableCell>
            <DenseTableCell>{template.subject}</DenseTableCell>
            <DenseTableCell>
              <ActionButton
                tooltip="Edit mail template"
                onClick={() => {
                  props.onEditTemplate?.(template.templateId);
                }}
              >
                <EditIcon />
              </ActionButton>
              <ActionButton
                tooltip="Send a test message to logged in account"
                onClick={() => {
                  props.onSendTestMessage?.(template.templateId);
                }}
              >
                <SendIcon />
              </ActionButton>
            </DenseTableCell>
          </TableRow>
        ))}
      </LoadingTableBody>
    </StyledTable>
  </TableContainer>
);

MailTemplateList.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      templateId: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      internalPurpose: PropTypes.string,
      subject: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
  onEditTemplate: PropTypes.func,
};

MailTemplateList.defaultProps = {
  templates: [],
  loading: false,
};

export default MailTemplateList;
