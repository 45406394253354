import { useState } from "react";
import { styled } from "@material-ui/styles";
import { postData } from "../../api/api";
import { reshapeImportedAccounts } from "../../data/reshapeAccounts";
import Error from "../Common/Error";
import useMutation from "../../hooks/useMutation";
import { useFetchApplications } from "../../hooks/useQueries";
import AccountData from "./AccountData";
import ImportReview from "./ImportReview";
import ImportToolbar from "./ImportToolbar";

const StyledContent = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 3),
}));

const StyledError = styled(Error)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const Import = (props) => {
  const [fields, setFields] = useState(
    "email,password,first,last,paramtext,source"
  );
  const [data, setData] = useState();

  const { applications } = useFetchApplications();

  const mutation = useMutation(
    (resource) => postData("/accounts/import", resource),
    {
      onSuccess: props.onClose,
    }
  );

  const handleImport = ({
    applicationId,
    copyUserDetails,
    copyUsersDetailsFromApplicationId,
    testMode,
  }) => {
    mutation.mutate({
      createMode: testMode ? "test" : "live",
      applicationId,
      copyDetails: copyUserDetails,
      copyFromApplicationId: copyUsersDetailsFromApplicationId,
      fields,
      data,
    });
  };

  const review = mutation.data?.data;

  return (
    <div>
      <ImportToolbar
        applications={applications?.filter(({ enabled }) => enabled)}
        importing={mutation.isLoading}
        onImport={handleImport}
      />

      <StyledContent>
        {mutation.error && (
          <StyledError message={mutation.error.response.data.message} />
        )}

        <AccountData
          fields={fields}
          data={data}
          onFieldsChange={setFields}
          onDataChange={setData}
        />

        {review && (
          <ImportReview
            summary={review.summary}
            accounts={review.outcomes && reshapeImportedAccounts(review)}
            csvDownloadId={review.importId}
          />
        )}
      </StyledContent>
    </div>
  );
};

export default Import;
