import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import QrCode from "./QrCode";

const StyledQrCode = styled(QrCode)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const TokenAuthentication = (props) => (
  <>
    {props.authenticationTokenSecretUrl && (
      <FormControl disabled={props.disabled}>
        <div>
          <StyledQrCode value={props.authenticationTokenSecretUrl} />
          <Typography variant="body1">
            {props.authenticationTokenSecretKey}
          </Typography>
        </div>
        <FormHelperText>Register with your 2FA application</FormHelperText>
      </FormControl>
    )}

    <TextField
      label="Authentication token"
      value={props.authenticationToken ?? ""}
      required
      fullWidth
      helperText="Use your 2FA app (e.g. Microsoft Authenticator) to obtain a token"
      disabled={props.disabled}
      onChange={(e) => props.onAuthenticationTokenChange?.(e.target.value)}
    />
  </>
);

TokenAuthentication.propTypes = {
  authenticationToken: PropTypes.string,
  authenticationTokenSecretUrl: PropTypes.string,
  authenticationTokenSecretKey: PropTypes.string,
  disabled: PropTypes.bool,
  onAuthenticationTokenChange: PropTypes.func,
};

TokenAuthentication.defaultProps = {
  disabled: false,
};

export default TokenAuthentication;
