import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import PrivateTextInput from "../Common/PrivateTextInput";

const PasswordAuthentication = (props) => (
  <>
    <TextField
      type="email"
      label="e-mail"
      value={props.email ?? ""}
      required
      fullWidth
      autoFocus
      autoComplete="username"
      onChange={(e) => {
        props.onEmailChange?.(e.target.value);
      }}
    />
    <PrivateTextInput
      label="Password"
      value={props.password ?? ""}
      required
      fullWidth
      helperText="Forgotten your password? Contact the system administrator."
      autoComplete="current-password"
      onChange={(e) => {
        props.onPasswordChange?.(e.target.value);
      }}
    />
  </>
);

PasswordAuthentication.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  onEmailChange: PropTypes.func,
  onPasswordChange: PropTypes.func,
};

export default PasswordAuthentication;
