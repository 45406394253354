import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import { Wifi as WifiIcon, WifiOff as WifiOffIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";

const StyledComponent = styled("div")({
  color: "orange",
});

const ConnectionStatus = (props) => {
  return (
    <StyledComponent className={props.className}>
      <Tooltip
        title={
          props.lastSuccessfulConnection
            ? `Last connected at ${props.lastSuccessfulConnection.toLocaleString()}`
            : "Disconnected from service"
        }
      >
        {props.lastSuccessfulConnection ? <WifiIcon /> : <WifiOffIcon />}
      </Tooltip>
    </StyledComponent>
  );
};

ConnectionStatus.propTypes = {
  lastSuccessfulConnection: PropTypes.instanceOf(Date),
};

export default ConnectionStatus;
