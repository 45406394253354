import PropTypes from "prop-types";
import { Table, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Edit as EditIcon, Send as SendIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import ActionButton from "../Common/ActionButton";
import DenseTableCell from "../Common/DenseTableCell";
import LoadingTableBody from "../Common/LoadingTableBody";

const StyledTable = styled(Table)(({ theme }) => ({
  width: "100%",
  "& th": {
    whiteSpace: "nowrap",
  },
  "& .action-buttons": {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: `${theme.spacing(0.5)}px`,
  },
  "& .email-list ul": {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
}));

const SystemMailList = (props) => (
  <TableContainer>
    <StyledTable size="small">
      <TableHead>
        <TableRow>
          <DenseTableCell>List code</DenseTableCell>
          <DenseTableCell>Purpose</DenseTableCell>
          <DenseTableCell>From name</DenseTableCell>
          <DenseTableCell>From e-mail</DenseTableCell>
          <DenseTableCell>e-mail list</DenseTableCell>
          <DenseTableCell />
        </TableRow>
      </TableHead>
      <LoadingTableBody
        loading={props.loading}
        colSpan={6}
        loadingMessage="Fetching mail destinations. Please wait."
      >
        {props.mailDestinations.map((mailDestination) => (
          <TableRow key={mailDestination.destinationId}>
            <DenseTableCell>{mailDestination.destinationId}</DenseTableCell>
            <DenseTableCell>{mailDestination.purpose}</DenseTableCell>
            <DenseTableCell>{mailDestination.fromName}</DenseTableCell>
            <DenseTableCell>{mailDestination.fromEmail}</DenseTableCell>
            <DenseTableCell className="email-list">
              <ul>
                {mailDestination.emailDestinations.map((email) => (
                  <li key={email}>{email}</li>
                ))}
              </ul>
            </DenseTableCell>
            <DenseTableCell>
              <div className="action-buttons">
                <ActionButton
                  tooltip="Edit mail destination"
                  onClick={() => {
                    props.onEditMailDestination?.(
                      mailDestination.destinationId
                    );
                  }}
                >
                  <EditIcon />
                </ActionButton>
                <ActionButton
                  tooltip="Send a test message"
                  onClick={() => {
                    props.onSendTestMessage?.(mailDestination.destinationId);
                  }}
                >
                  <SendIcon />
                </ActionButton>
              </div>
            </DenseTableCell>
          </TableRow>
        ))}
      </LoadingTableBody>
    </StyledTable>
  </TableContainer>
);

SystemMailList.propTypes = {
  mailDestinations: PropTypes.arrayOf(
    PropTypes.shape({
      destinationId: PropTypes.string.isRequired,
      purpose: PropTypes.string.isRequired,
      fromName: PropTypes.string.isRequired,
      fromEmail: PropTypes.string.isRequired,
      emailDestinations: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  loading: PropTypes.bool,
  onEditMailDestination: PropTypes.func,
  onSendTestMessage: PropTypes.func,
};

SystemMailList.defaultProps = {
  mailDestinations: [],
  loading: false,
};

export default SystemMailList;
