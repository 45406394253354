import { Table, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import ActionButton from "../Common/ActionButton";
import DenseTableCell from "../Common/DenseTableCell";
import LoadingTableBody from "../Common/LoadingTableBody";
import { ReactComponent as KeysIcon } from "../../images/keys.svg";

const createKeyDescription = (keys) => {
  if (!keys) {
    return "";
  }

  let description = keys.slice(0, 3).join(", ");

  if (keys.length > 3) {
    description += ` (and ${keys.length - 3} more)`;
  }

  return description;
};

const StyledTable = styled(Table)(({ theme }) => ({
  width: "fit-content",
  "& th": {
    whiteSpace: "nowrap",
  },
  "& .action-buttons": {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: `${theme.spacing(0.5)}px`,
  },
  "& .keys-icon": {
    width: 24,
  },
}));

const RoleList = (props) => (
  <TableContainer>
    <StyledTable size="small">
      <TableHead>
        <TableRow>
          <DenseTableCell>Role</DenseTableCell>
          <DenseTableCell>Keys</DenseTableCell>
          <DenseTableCell />
        </TableRow>
      </TableHead>
      <LoadingTableBody
        loading={props.loading}
        colSpan={3}
        loadingMessage="Fetching roles. Please wait."
      >
        {props.roles
          .sort((a, b) => a.roleId.localeCompare(b.roleId))
          .map((role) => (
            <TableRow key={role.roleId}>
              <DenseTableCell>{role.roleId}</DenseTableCell>
              <DenseTableCell>{createKeyDescription(role.keys)}</DenseTableCell>
              <DenseTableCell>
                <div>
                  <ActionButton
                    tooltip="Edit role"
                    onClick={() => {
                      props.onEditRole?.(role.roleId);
                    }}
                  >
                    <EditIcon />
                  </ActionButton>
                  <ActionButton
                    tooltip="Assign keys"
                    onClick={() => {
                      props.onAssignKeys?.(role.roleId);
                    }}
                  >
                    <KeysIcon className="keys-icon" />
                  </ActionButton>
                </div>
              </DenseTableCell>
            </TableRow>
          ))}
      </LoadingTableBody>
    </StyledTable>
  </TableContainer>
);

RoleList.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      roleId: PropTypes.string.isRequired,
      keys: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  loading: PropTypes.bool,
  onEditRole: PropTypes.func,
  onAssignKeys: PropTypes.func,
};

RoleList.defaultProps = {
  roles: [],
  loading: false,
};

export default RoleList;
