import { useState } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControlLabel,
  Select,
  Switch,
  Toolbar,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import LoadingButton from "../Common/LoadingButton";

const ApplicationOptions = (props) => {
  const applications = props.applications ?? [];

  return (
    <>
      <option value="">{props.defaultOption ?? "Select application..."}</option>
      {applications
        .filter(
          (application) =>
            application.applicationId !== props.excludedApplicationId
        )
        .sort((a, b) => a.applicationId.localeCompare(b.applicationId))
        .map((application) => (
          <option
            key={application.applicationId}
            value={application.applicationId}
          >
            {application.humanName
              ? `${application.applicationId}, ${application.humanName}`
              : application.applicationId}
          </option>
        ))}
    </>
  );
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "& .control-group": {
    alignItems: "center",
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: `${theme.spacing(4)}px`,
    "& > .MuiFormControlLabel-root": {
      marginRight: 0,
    },
  },
}));

const StyledCopyUserDetails = styled("div")({
  alignItems: "center",
  display: "grid",
  gridAutoFlow: "column",
  whiteSpace: "nowrap",
});

const ImportToolbar = (props) => {
  const [applicationId, setApplicationId] = useState();
  const [testMode, setTestMode] = useState(true);
  const [advancedFeatures, setAdvancedFeatures] = useState(false);
  const [copyUserDetails, setCopyUserDetails] = useState(false);
  const [
    copyUsersDetailsFromApplicationId,
    setCopyUsersDetailsFromApplicationId,
  ] = useState();

  return (
    <StyledToolbar>
      <form
        className="control-group"
        onSubmit={(e) => {
          props.onImport?.({
            applicationId,
            copyUserDetails,
            copyUsersDetailsFromApplicationId,
            testMode,
          });

          e.preventDefault();
        }}
      >
        <Select
          native
          value={applicationId || ""}
          onChange={(e) => {
            setApplicationId(e.target.value || undefined);
          }}
        >
          <ApplicationOptions applications={props.applications} />
        </Select>

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          disabled={!applicationId}
          loading={props.importing}
        >
          Import
        </LoadingButton>

        <FormControlLabel
          control={<Switch color="primary" />}
          label="Test"
          checked={testMode}
          onChange={(e) => {
            setTestMode(e.target.checked);
          }}
        />

        <FormControlLabel
          control={<Switch color="primary" />}
          label="Advanced"
          checked={advancedFeatures}
          onChange={(e) => {
            setAdvancedFeatures(e.target.checked);
          }}
        />

        {advancedFeatures ? (
          <StyledCopyUserDetails>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={copyUserDetails}
                  onClick={(e) => {
                    setCopyUserDetails((copyUserDetails) => !copyUserDetails);
                  }}
                />
              }
              label="Copy user details from"
            />

            <Select
              native
              value={copyUsersDetailsFromApplicationId || ""}
              disabled={!copyUserDetails}
              onChange={(e) => {
                setCopyUsersDetailsFromApplicationId(
                  e.target.value || undefined
                );
              }}
            >
              <ApplicationOptions
                applications={props.applications}
                defaultOption="Select source application..."
                excludedApplicationId={applicationId}
              />
            </Select>
          </StyledCopyUserDetails>
        ) : null}
      </form>
    </StyledToolbar>
  );
};

ImportToolbar.propTypes = {
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      applicationId: PropTypes.string.isRequired,
      humanName: PropTypes.string,
    })
  ),
  importing: PropTypes.bool,
  onImport: PropTypes.func,
};

ImportToolbar.defaultProps = {
  applications: [],
  importing: false,
};

export default ImportToolbar;
