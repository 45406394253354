import PropTypes from "prop-types";
import { Table, TableContainer, TableHead, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { styled } from "@material-ui/styles";
import ActionButton from "../Common/ActionButton";
import DenseTableCell from "../Common/DenseTableCell";
import EnabledIndicator from "../Common/EnabledIndicator";
import LoadingTableBody from "../Common/LoadingTableBody";

const StyledTable = styled(Table)(({ theme }) => ({
  width: "100%",
  "& th": {
    whiteSpace: "nowrap",
  },
  "& .action-buttons": {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: `${theme.spacing(0.5)}px`,
  },
}));

const ApplicationList = (props) => {
  let applications = props.applications;

  if (!props.showInactive) {
    applications = applications.filter(({ enabled }) => enabled);
  }

  return (
    <TableContainer>
      <StyledTable size="small">
        <TableHead>
          <TableRow>
            <DenseTableCell />
            <DenseTableCell>ID</DenseTableCell>
            <DenseTableCell>Name</DenseTableCell>
            <DenseTableCell>Visibility</DenseTableCell>
            <DenseTableCell>Sub-application</DenseTableCell>
            <DenseTableCell>Access URL</DenseTableCell>
            <DenseTableCell>Logo URL</DenseTableCell>
            <DenseTableCell />
          </TableRow>
        </TableHead>
        <LoadingTableBody
          loading={props.loading}
          colSpan={8}
          loadingMessage="Fetching applications. Please wait."
        >
          {applications
            .sort((a, b) => a.applicationId.localeCompare(b.applicationId))
            .map((application) => (
              <TableRow key={application.applicationId}>
                <DenseTableCell>
                  <EnabledIndicator
                    enabled={application.enabled}
                    color="primary"
                  />
                </DenseTableCell>
                <DenseTableCell>{application.applicationId}</DenseTableCell>
                <DenseTableCell>{application.humanName}</DenseTableCell>
                <DenseTableCell>{application.accessMode}</DenseTableCell>
                <DenseTableCell>
                  {application.superApplicationId}
                </DenseTableCell>
                <DenseTableCell>{application.url}</DenseTableCell>
                <DenseTableCell>{application.webInfo?.logoUrl}</DenseTableCell>
                <DenseTableCell>
                  <div className="action-buttons">
                    <ActionButton
                      tooltip="Edit application"
                      onClick={() => {
                        props.onEditApplication?.(application.applicationId);
                      }}
                    >
                      <EditIcon />
                    </ActionButton>
                  </div>
                </DenseTableCell>
              </TableRow>
            ))}
        </LoadingTableBody>
      </StyledTable>
    </TableContainer>
  );
};

ApplicationList.propTypes = {
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      enabled: PropTypes.bool.isRequired,
      applicationId: PropTypes.string.isRequired,
      humanName: PropTypes.string,
      superApplicationId: PropTypes.string,
      accessMode: PropTypes.string,
      url: PropTypes.string,
      webInfo: PropTypes.shape({
        logoUrl: PropTypes.string,
      }),
    })
  ),
  showInactive: PropTypes.bool,
  loading: PropTypes.bool,
  onEditApplication: PropTypes.func,
};

ApplicationList.defaultProps = {
  applications: [],
  showInactive: true,
  loading: false,
};

export default ApplicationList;
