import PropTypes from "prop-types";
import { AppBar, Button, Toolbar } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { useMediaQuery } from "react-responsive";
import { useAuthentication } from "../../hooks/useAuthentication";
import useCheckService from "../../hooks/useCheckService";
import ComputerPrimaryContent from "./ComputerPrimaryContent";
import ConnectionStatus from "./ConnectionStatus";
import MobilePrimaryContent from "./MobilePrimaryContent";

const StyledAppBar = styled(AppBar)({
  display: "flex",
  "& .MuiButton-label": {
    textTransform: "capitalize",
    whiteSpace: "nowrap",
  },
});

const StyledConnectionStatus = styled(ConnectionStatus)(({ theme }) => ({
  marginLeft: `${theme.spacing(2)}px`,
}));

export const NavBarPresenter = (props) => {
  const isComputer = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  return (
    <StyledAppBar position="sticky">
      <Toolbar>
        {isComputer ? (
          <ComputerPrimaryContent signedIn={props.signedIn} />
        ) : (
          <MobilePrimaryContent signedIn={props.signedIn} />
        )}

        {props.signedIn && (
          <div>
            <Button color="inherit" onClick={() => props.onSignOut()}>
              Sign out
            </Button>
          </div>
        )}

        <StyledConnectionStatus
          lastSuccessfulConnection={props.lastSuccessfulServiceCheck}
        />
      </Toolbar>
    </StyledAppBar>
  );
};

NavBarPresenter.propTypes = {
  signedIn: PropTypes.bool,
  lastSuccessfulServiceCheck: PropTypes.instanceOf(Date),
  onSignOut: PropTypes.func,
};

NavBarPresenter.defaultProps = {
  connected: true,
  signedIn: false,
};

const NavBar = (props) => {
  const authentication = useAuthentication();

  const lastSuccessfulServiceCheck = useCheckService();

  return (
    <NavBarPresenter
      {...props}
      signedIn={!!authentication.user}
      lastSuccessfulServiceCheck={lastSuccessfulServiceCheck}
      onSignOut={authentication.signOut}
    />
  );
};

export default NavBar;
