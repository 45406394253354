import { useState } from "react";
import { useFetchRoles } from "../../hooks/useQueries";
import useResourceEditing from "../../hooks/useResourceEditing";
import Error from "../Common/Error";
import AssignKeysDialog from "./AssignKeysDialog";
import RoleDetailsDialog from "./RoleDetailsDialog";
import RoleList from "./RoleList";
import RolesToolbar from "./RolesToolbar";

const PopulatedRoleDetailsDialog = (props) => {
  const role = props.role;

  return (
    <RoleDetailsDialog
      open={props.open}
      roles={props.roles}
      roleId={role.roleId}
      onSave={props.onSave}
      onCancel={props.onCancel}
      onDelete={props.onDelete}
    />
  );
};

const Roles = () => {
  const [activeRoleId, setActiveRoleId] = useState();

  const { isLoading, isError, roles, error } = useFetchRoles();

  const {
    resourceState,
    editResource,
    addResource,
    updateResource,
    deleteResource,
    cancelResourceEditing,
  } = useResourceEditing("roles/roles");

  if (isError) {
    const message = `Oops! Failed to fetch roles. ${error.message}. Try refreshing the browser.`;

    return <Error message={message} />;
  }

  const handleEditRole = (roleId) => {
    const role = roles.find((role) => role.roleId === roleId);

    editResource(role);
  };

  const handleSaveRole = (role) => {
    const resource = {
      ...role,
      id: role.roleId,
    };

    if (!resourceState.data.roleId) {
      addResource.mutate(resource);
    } else {
      updateResource.mutate(resource);
    }
  };

  const handleDeleteRole = (keyId) => {
    deleteResource.mutate(keyId);
  };

  const handleAssignKeys = (role, keyIds) => {
    const resource = {
      ...role,
      id: role.roleId,
      keys: keyIds,
    };

    updateResource.mutate(resource);

    setActiveRoleId();
  };

  const activeRole = roles?.find((role) => role.roleId === activeRoleId);

  return (
    <div>
      <RolesToolbar
        onAddRole={() => {
          editResource({});
        }}
      />
      <RoleList
        roles={roles}
        loading={isLoading}
        onEditRole={handleEditRole}
        onAssignKeys={setActiveRoleId}
      />
      {resourceState.data && (
        <PopulatedRoleDetailsDialog
          open={true}
          roles={roles}
          role={resourceState.data}
          error={resourceState.error}
          onSave={handleSaveRole}
          onCancel={cancelResourceEditing}
          onDelete={handleDeleteRole}
        />
      )}
      {activeRoleId && (
        <AssignKeysDialog
          open={Boolean(activeRoleId)}
          role={activeRole}
          assignedKeyIds={activeRole.keys}
          onSave={(keyIds) => {
            handleAssignKeys(activeRole, keyIds);
          }}
          onCancel={() => {
            setActiveRoleId();
          }}
        />
      )}
    </div>
  );
};

export default Roles;
