import { useState, useEffect } from "react";

const useLocalStorage = (key, defaultValue) => {
  const stored = localStorage.getItem(key);
  const initialValue = stored ? JSON.parse(stored) : defaultValue;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value != null) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
