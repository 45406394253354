import PropTypes from "prop-types";
import {
  Check as CheckIcon,
  NotInterested as NotInterestedIcon,
} from "@material-ui/icons";

const EnabledIndicator = (props) => {
  return props.enabled ? (
    <CheckIcon color={props.color} />
  ) : (
    <NotInterestedIcon color={props.color} />
  );
};

EnabledIndicator.propTypes = {
  enabled: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

export default EnabledIndicator;
