import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import DialogForm from "../Common/DialogForm";
import { useFetchQueries } from "../../hooks/useQueries";

const StyledDialogForm = styled(DialogForm)({
  "& .query": {
    width: "100%",
  },
});

export const StatisticExportDialogPresenter = (props) => {
  const [query, setQuery] = useState();

  const firstQuery = props.queries[0]?.queryId;

  useEffect(() => {
    setQuery(firstQuery);
  }, [firstQuery]);

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle>Reports</DialogTitle>
      <StyledDialogForm>
        <Typography component="div">Select the report for export</Typography>
        <FormControl className="query">
          <InputLabel htmlFor="query" required shrink>
            Report
          </InputLabel>
          <Select
            id="query"
            value={query}
            native
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          >
            {props.queries
              .sort((a, b) => a.queryName.localeCompare(b.queryName))
              .map((query) => (
                <option key={query.queryId} value={query.queryId}>
                  {query.queryName}
                </option>
              ))}
          </Select>
        </FormControl>
      </StyledDialogForm>
      <DialogActions>
        <Button
          color="primary"
          disabled={!query}
          onClick={() => {
            props.onDownload?.(query);
          }}
        >
          Download
        </Button>
        <Button color="primary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

StatisticExportDialogPresenter.propTypes = {
  open: PropTypes.bool.isRequired,
  queries: PropTypes.arrayOf(
    PropTypes.shape({
      queryId: PropTypes.string.isRequired,
      queryName: PropTypes.string.isRequired,
    })
  ),
  onDownload: PropTypes.func,
  onClose: PropTypes.func,
};

StatisticExportDialogPresenter.defaultProps = {
  queries: [],
};

const StatisticExportDialog = (props) => {
  const { queries } = useFetchQueries();

  return <StatisticExportDialogPresenter {...props} queries={queries} />;
};

export default StatisticExportDialog;
