import { Button, Dialog, DialogTitle, TextField } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import LockingDialogActions from "../Common/LockingDialogActions";
import DialogForm from "../Common/DialogForm";
import LoadingButton from "../Common/LoadingButton";
import DeleteKeyDialog from "./DeleteKeyDialog";

const StyledDialogForm = styled(DialogForm)(() => ({
  "& .MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
  },
}));

const KeyDetailsDialog = ({ keys, ...rest }) => {
  const [keyId, setKeyId] = useState(rest.keyId ?? "");
  const [name, setName] = useState(rest.name ?? "");
  const [value, setValue] = useState(rest.value ?? "");
  const [locked, setLocked] = useState(true);
  const [confirmDeletionDialogOpen, setConfirmDeletionDialogOpen] = useState(
    false
  );

  const keyIds = useMemo(() => keys.map((key) => key.keyId), [keys]);

  const checkValidity = () =>
    Boolean(
      keyId.trim() &&
        name.trim() &&
        value.trim() &&
        (rest.keyId || !keyIds.includes(keyId))
    );

  const handleDelete = () => {
    setConfirmDeletionDialogOpen(false);

    rest.onDelete?.(rest.keyId);
  };

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={rest.open}>
        <DialogTitle>Key details for </DialogTitle>
        <StyledDialogForm>
          <TextField
            label="ID"
            value={keyId}
            required
            fullWidth
            helperText={
              !rest.keyId && keyIds.includes(keyId)
                ? "Key ID already exists"
                : ""
            }
            disabled={Boolean(rest.keyId)}
            onChange={(e) => {
              setKeyId(e.target.value);
            }}
          />
          <TextField
            label="Name"
            value={name}
            required
            fullWidth
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextField
            label="Value"
            value={value}
            required
            fullWidth
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </StyledDialogForm>
        <LockingDialogActions locked={locked} onLockChange={setLocked}>
          <Button
            color="primary"
            onClick={() => {
              rest.onCancel?.();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            color="secondary"
            disabled={locked || !rest.keyId}
            loading={false}
            onClick={() => {
              setConfirmDeletionDialogOpen(true);
            }}
          >
            Delete
          </LoadingButton>
          <LoadingButton
            color="primary"
            disabled={!checkValidity()}
            loading={false}
            onClick={() => {
              rest.onSave?.({
                keyId: keyId.trim(),
                name: name.trim(),
                value,
              });
            }}
          >
            Save
          </LoadingButton>
        </LockingDialogActions>
      </Dialog>

      {confirmDeletionDialogOpen && (
        <DeleteKeyDialog
          open={true}
          onCancel={() => {
            setConfirmDeletionDialogOpen(false);
          }}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

KeyDetailsDialog.propTypes = {
  open: PropTypes.bool,
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      keyId: PropTypes.string.isRequired,
    })
  ),
  keyId: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
};

KeyDetailsDialog.defaultProps = {
  open: false,
  keys: [],
};

export default KeyDetailsDialog;
