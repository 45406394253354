export const spacing = (...args) => (props) =>
  args.length === 1
    ? `${props.theme.spacing(...args)}px`
    : props.theme.spacing(...args);

export const createAction = (type, payload) => ({
  type,
  payload,
});

export const createGuid = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
