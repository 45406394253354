import Error from "../Common/Error";
import { useFetchActivities } from "../../hooks/useQueries";
import ActivityList from "./ActivityList";

const Activity = () => {
  const { isLoading, isError, activities, error } = useFetchActivities();

  if (isError) {
    const message = `Oops! Failed to fetch activity. ${error.message}. Try refreshing the browser.`;

    return <Error message={message} />;
  }

  return <ActivityList activities={activities} loading={isLoading} />;
};

export default Activity;
