import { Table, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import ActionButton from "../Common/ActionButton";
import DenseTableCell from "../Common/DenseTableCell";
import LoadingTableBody from "../Common/LoadingTableBody";
import { ReactComponent as KeyHoldersIcon } from "../../images/key-holders.svg";

const StyledTable = styled(Table)(({ theme }) => ({
  width: "fit-content",
  "& th": {
    whiteSpace: "nowrap",
  },
  "& .action-buttons": {
    display: "grid",
    gridAutoFlow: "column",
    gridColumnGap: `${theme.spacing(0.5)}px`,
  },
  "& .key-holders-icon": {
    width: 24,
  },
}));

const KeyList = (props) => (
  <TableContainer>
    <StyledTable size="small">
      <TableHead>
        <TableRow>
          <DenseTableCell>ID</DenseTableCell>
          <DenseTableCell>Name</DenseTableCell>
          <DenseTableCell>Value</DenseTableCell>
          <DenseTableCell />
        </TableRow>
      </TableHead>
      <LoadingTableBody
        loading={props.loading}
        colSpan={4}
        loadingMessage="Fetching keys. Please wait."
      >
        {props.keys
          .sort(
            (a, b) =>
              a.name.localeCompare(b.name) || a.keyId.localeCompare(b.keyId)
          )
          .map((key) => (
            <TableRow key={key.keyId}>
              <DenseTableCell>{key.keyId}</DenseTableCell>
              <DenseTableCell>{key.name}</DenseTableCell>
              <DenseTableCell>
                {props.revealValues ? key.value : "********"}
              </DenseTableCell>
              <DenseTableCell>
                <div>
                  <ActionButton
                    tooltip="Edit key"
                    onClick={() => {
                      props.onEditKey?.(key.keyId);
                    }}
                  >
                    <EditIcon />
                  </ActionButton>
                  <ActionButton
                    tooltip="View key holders"
                    onClick={() => {
                      props.onViewKeyHolders?.(key.keyId);
                    }}
                  >
                    <KeyHoldersIcon className="key-holders-icon" />
                  </ActionButton>
                </div>
              </DenseTableCell>
            </TableRow>
          ))}
      </LoadingTableBody>
    </StyledTable>
  </TableContainer>
);

KeyList.propTypes = {
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      keyId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  revealValues: PropTypes.bool,
  loading: PropTypes.bool,
  onEditKey: PropTypes.func,
  onViewKeyHolders: PropTypes.func,
};

KeyList.defaultProps = {
  keys: [],
  revealValues: false,
  loading: false,
};

export default KeyList;
