import { styled } from "@material-ui/styles";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes } from "react-router-dom";
import { useApplicationDetails } from "../hooks/useApplicationDetails";
import { useAuthentication } from "../hooks/useAuthentication";
import Accounts from "./Accounts/Accounts";
import Activity from "./Activity/Activity";
import Applications from "./Applications/Applications";
import LoginDialog from "./Authentication/LoginDialog";
import ApplicationBanners from "./Common/ApplicationBanners";
import Error from "./Common/Error";
import Import from "./Import/Import";
import Keys from "./Keys/Keys";
import MailTemplatePreview from "./MailTemplates/MailTemplatePreview";
import MailTemplates from "./MailTemplates/MailTemplates";
import NavBar from "./NavBar/NavBar";
import Roles from "./Roles/Roles";
import SystemMail from "./SystemMail/SystemMail";

const StyledContent = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 1, 1),
}));

const ErrorBoundedComponent = (props) => (
  <ErrorBoundary key={props.id} FallbackComponent={Error}>
    {props.children}
  </ErrorBoundary>
);

const ErrorBoundedRoute = (props) => (
  <Route
    path={props.path}
    element={
      <ErrorBoundedComponent id={props.path}>
        {props.children}
      </ErrorBoundedComponent>
    }
  />
);

const Main = () => {
  const { title, splashMessage, footerMessage } = useApplicationDetails();
  const authentication = useAuthentication();

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <NavBar />

      {authentication.user ? (
        <StyledContent>
          <ApplicationBanners
            splashMessage={splashMessage}
            footerMessage={footerMessage}
          >
            <Routes>
              <ErrorBoundedRoute path="/activity">
                <Activity />
              </ErrorBoundedRoute>
              <ErrorBoundedRoute path="/accounts">
                <Accounts />
              </ErrorBoundedRoute>
              <ErrorBoundedRoute path="/applications">
                <Applications />
              </ErrorBoundedRoute>
              <ErrorBoundedRoute path="/import">
                <Import />
              </ErrorBoundedRoute>
              <ErrorBoundedRoute path="/system-mail">
                <SystemMail />
              </ErrorBoundedRoute>
              <ErrorBoundedRoute path="/mail-templates">
                <MailTemplates />
              </ErrorBoundedRoute>
              <ErrorBoundedRoute path="/mail-templates/preview">
                <MailTemplatePreview />
              </ErrorBoundedRoute>
              <ErrorBoundedRoute path="/roles">
                <Roles />
              </ErrorBoundedRoute>
              <ErrorBoundedRoute path="/keys">
                <Keys />
              </ErrorBoundedRoute>
              <Route element={<Navigate to="activity" />} />
            </Routes>
          </ApplicationBanners>
        </StyledContent>
      ) : (
        <LoginDialog open={true} />
      )}
    </>
  );
};

export default Main;
