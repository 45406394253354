import { Fab, Toolbar, Tooltip } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "column",
  gridColumnGap: `${theme.spacing(3)}px`,
  width: "fit-content",
}));

const RolesToolbar = (props) => (
  <StyledToolbar>
    <Tooltip title="Create new role">
      <Fab
        color="primary"
        size="small"
        onClick={() => {
          props.onAddRole?.();
        }}
      >
        <AddIcon />
      </Fab>
    </Tooltip>
  </StyledToolbar>
);

RolesToolbar.propTypes = {
  onAddRole: PropTypes.func,
};

export default RolesToolbar;
