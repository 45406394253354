import {
  Button,
  Fab,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Add as AddIcon, BarChart as BarChartIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import PropTypes from "prop-types";
import { useState } from "react";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "column",
  gridColumnGap: `${theme.spacing(3)}px`,
  width: "fit-content",

  "& .MuiFormControl-root": {
    minWidth: "100px",
  },
}));

const FetchSettings = (props) => {
  const [recordLimit, setRecordLimit] = useState(props.recordLimit ?? 10);
  const [applicationId, setApplicationId] = useState(
    props.applicationId ?? "All"
  );
  const [filterTerm, setFilterTerm] = useState(props.filterTerm);

  return (
    <form
      className={props.className}
      onSubmit={(e) => {
        props.onFetch?.({
          recordLimit: recordLimit || undefined,
          applicationId:
            applicationId && applicationId !== "All"
              ? applicationId
              : undefined,
          filterTerm: filterTerm ? filterTerm : undefined,
        });

        e.preventDefault();
      }}
    >
      <FormControl>
        <InputLabel htmlFor="record-limit" shrink>
          Record limit
        </InputLabel>
        <Select
          id="record-limit"
          native
          value={recordLimit}
          onChange={(e) => {
            setRecordLimit(parseInt(e.target.value, 10));
          }}
        >
          <option value={10}>10 records</option>
          <option value={100}>100 records</option>
          <option value={0}>All records</option>
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel htmlFor="application" shrink>
          Application
        </InputLabel>
        <Select
          id="application"
          native
          value={applicationId ?? ""}
          onChange={(e) => {
            setApplicationId(e.target.value);
          }}
        >
          {[
            {
              applicationId: "All",
            },
            ...props.applications.sort((a, b) =>
              a.applicationId.localeCompare(b.applicationId)
            ),
          ].map((application) => (
            <option
              key={application.applicationId}
              value={application.applicationId}
            >
              {application.applicationId}
            </option>
          ))}
        </Select>
      </FormControl>

      <TextField
        type="search"
        label="Name/e-mail/ID"
        InputLabelProps={{ shrink: true }}
        placeholder="All"
        onChange={(e) => {
          setFilterTerm(e.target.value);
        }}
      />

      <Button type="submit" variant="contained" color="primary" size="small">
        Fetch
      </Button>
    </form>
  );
};

const StyledFetchSettings = styled(FetchSettings)(({ theme }) => ({
  alignItems: "center",
  display: "grid",
  gridAutoFlow: "column",
  gridColumnGap: `${theme.spacing(1)}px`,
  whiteSpace: "nowrap",
}));

const AccountsToolbar = (props) => (
  <StyledToolbar>
    <Tooltip title="Create new account">
      <Fab color="primary" size="small" onClick={props.onAddAccount}>
        <AddIcon />
      </Fab>
    </Tooltip>

    <StyledFetchSettings
      recordLimit={props.recordLimit}
      applications={props.applications}
      applicationId={props.applicationId}
      onFetch={props.onFetch}
    />

    <Tooltip title="Export statistics">
      <Fab color="primary" size="small" onClick={props.onExportStatistics}>
        <BarChartIcon />
      </Fab>
    </Tooltip>

    <Tooltip title="Download CSV">
      <Fab color="primary" size="small" onClick={props.onDownloadCsv}>
        CSV
      </Fab>
    </Tooltip>
  </StyledToolbar>
);

AccountsToolbar.propTypes = {
  recordLimit: PropTypes.number,
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      applicationId: PropTypes.string.isRequired,
    })
  ),
  applicationId: PropTypes.string,
  onAddAccount: PropTypes.func,
  onFetch: PropTypes.func,
  onExportStatistics: PropTypes.func,
  onDownloadCsv: PropTypes.func,
};

AccountsToolbar.defaultProps = {
  applications: [],
  applicationId: "All",
};

export default AccountsToolbar;
