import PropTypes from "prop-types";
import QrCodeReact from "qrcode.react";

const QrCode = (props) => (
  <QrCodeReact
    renderAs={props.renderAs}
    value={props.value}
    className={props.className}
  />
);

QrCode.propTypes = {
  value: PropTypes.string.isRequired,
  renderAs: PropTypes.oneOf(["canvas", "svg"]),
};

QrCode.defaultProps = {
  renderAs: "svg",
};

export default QrCode;
